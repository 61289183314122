import { useCallback, useMemo } from "react"
import { useMutation, useQuery } from "@apollo/client"
import { History } from "react-router-dom"
import {
  adminGetEventPopups,
  adminRemoveEventPopupById,
  EventPopup,
  GetEventPopupsInput,
  GetEventPopupsResult,
} from "services/graphql/queries/eventPopup.queries"
import _ from "lodash"
import useInfinityScroll from "hooks/useInfinityScroll"
import { toast } from "react-toastify"

const EVENT_POPUPS_FETCH_PAGE_SIZE = 15

const eventPopupObjectKeys = [
  "id",
  "title",
  "content",
  "ctaText",
  "startDate",
  "endDate",
  "zipcodes",
  "onlyForKeeperIds",
  "redirectTo",
  "image",
]

type UseEventPopupsListType = {
  handleFileChange: (e) => void
  redirectToAddEventPopup: () => void
  exportEventPopup: (id: string) => void
  redirectToEditEventPopup: (id: string) => void
  removeEventPopup: (id: string) => void
  eventPopups: EventPopup[]
  loading: boolean
}

export const useEventPopupsList = (history: History): UseEventPopupsListType => {
  const {
    data,
    loading: isGetEventPopupsLoading,
    fetchMore,
  } = useQuery<GetEventPopupsResult, GetEventPopupsInput>(adminGetEventPopups, {
    variables: { input: { take: EVENT_POPUPS_FETCH_PAGE_SIZE, skip: 0 } },
  })

  const [removeEventPopupMutation, { loading: isRemoveLoading }] = useMutation<
    { adminRemoveEventPopupById: boolean },
    { id: string }
  >(adminRemoveEventPopupById)

  const loading = isRemoveLoading || isGetEventPopupsLoading

  const eventPopups = useMemo(() => data?.adminGetEventPopups.eventPopups, [data?.adminGetEventPopups.eventPopups])

  const fetchMoreEventPopups = useCallback(
    async (take = EVENT_POPUPS_FETCH_PAGE_SIZE, skip = eventPopups?.length || 0) => {
      return await fetchMore({
        variables: { input: { take, skip } },
        updateQuery: (prevResult, { fetchMoreResult }) => {
          const prevEventPopups = skip === 0 ? [] : prevResult.adminGetEventPopups.eventPopups
          const commonEdges = _.uniqBy([...prevEventPopups, ...fetchMoreResult.adminGetEventPopups.eventPopups], "id")
          fetchMoreResult.adminGetEventPopups.eventPopups = commonEdges
          return fetchMoreResult
        },
      })
    },
    [fetchMore, eventPopups],
  )

  useInfinityScroll({
    scrollingTableSelector: "#scrollableEventPopupsTableWrapper",
    hasMore: data?.adminGetEventPopups.hasNextPage,
    loading,
    fetchMore: fetchMoreEventPopups,
  })

  const handleFileChange = async e => {
    const file = e.target.files[0]
    if (file?.type !== "application/json") return toast.error("Provided file is not a valid JSON!")

    const reader = new FileReader()

    reader.onloadend = () => {
      try {
        const parsedJson = JSON.parse(reader.result as string)
        if (!Object.keys(parsedJson).some(key => eventPopupObjectKeys.includes(key))) {
          throw new Error("Invalid JSON provided")
        }
        const eventPopup = {}
        eventPopupObjectKeys.forEach(key => (eventPopup[key] = parsedJson[key]))
        history.push({
          pathname: "/event-popup-edit",
          state: { eventPopup: eventPopup },
        })
      } catch (e) {
        toast.error("Provided file is not a valid JSON!")
      }
    }

    reader.readAsText(file)
  }

  const redirectToAddEventPopup = () => history.push({ pathname: "/event-popup-edit" })

  const exportEventPopup = (id: string) => {
    /** Find the specific event popup by ID and create a new object to export */
    const eventPopup = eventPopups.find(eventPopup => eventPopup.id === id)
    const eventPopupToExport = {} as EventPopup
    eventPopupObjectKeys.forEach(key => (eventPopupToExport[key] = eventPopup[key]))

    /** Convert the object to a JSON string and create a blob for the JSON data */
    const jsonData = JSON.stringify(eventPopupToExport, null, 2)
    const blob = new Blob([jsonData], { type: "application/json" })

    /** Create a download link and set its URL and download filename */
    const downloadLink = document.createElement("a")
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.download = `event-popup-${eventPopupToExport.id}.json`

    /** Trigger the download */
    downloadLink.click()

    URL.revokeObjectURL(downloadLink.href)
  }

  const redirectToEditEventPopup = (id: string) =>
    history.push({
      pathname: "/event-popup-edit",
      state: { eventPopup: eventPopups?.find(eventPopups => eventPopups.id === id) },
    })

  const removeEventPopup = (id: string) =>
    removeEventPopupMutation({
      variables: { id },
      refetchQueries: [
        {
          query: adminGetEventPopups,
          variables: { input: { take: Math.max(eventPopups?.length, EVENT_POPUPS_FETCH_PAGE_SIZE), skip: 0 } },
        },
      ],
      onCompleted: () => {
        toast.success("Event Popup successfully removed!")
      },
    })

  return {
    handleFileChange,
    redirectToAddEventPopup,
    exportEventPopup,
    redirectToEditEventPopup,
    removeEventPopup,
    eventPopups,
    loading,
  }
}
