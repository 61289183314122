import { gql } from "@apollo/client"
import { BtocReturnRequestForm } from "types/return-request.types"

export interface GetBtocReturnRequestDetailsQuery {
  getBtocReturnRequestDetails: {
    id: string
    nbReturnsRequested: number
    btocReturnRequestForms: BtocReturnRequestForm[]
  }
}

export const getBtocReturnRequestDetails = gql`
  query getBtocReturnRequestDetails($parcelId: String!) {
    getBtocReturnRequestDetails(parcelId: $parcelId) {
      id
      nbReturnsRequested
      btocReturnRequestForms {
        id
        status
        returningCarrier {
          id
          collectionOptions {
            helpUrl
            minimumHour
            maximumHour
            minimumSlotDuration
          }
        }
        returningCarrierId
        collectOptionsStartHour
        collectOptionsEndHour
        collectOptionsAvailableDays
        reason
        pickupDate
        interval {
          end
          start
        }
        dimensions
        weight
        cotransportationCode
        referenceCode
        companyAddress {
          deliveryAddressId
          deliveryAddressName
          deliveryAddress
          deliveryPhone
          deliveryInstructions
          deliveryAddressZipcode
          deliveryAddressCity
          latitude
          longitude
        }
      }
    }
  }
`
