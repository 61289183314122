import React from "react"
import { Icon, Popup } from "semantic-ui-react"
import { OpenAiValidationStatus } from "types/keeper.types"
import { COLOR } from "utils/color"
import {
  StatusContainer,
  StatusText,
  InfoIconWrapper,
  PopupContent,
  ValidationItem,
  ValidationStatus,
  AIDecisionText,
} from "./OpenAiValidationStatusComponent.styled"

interface OpenAiValidationStatusProps {
  currentOpenAiValidationLog: {
    status?: OpenAiValidationStatus
    idcardStatus?: OpenAiValidationStatus
    addressStatus?: OpenAiValidationStatus
    explanationToAgent: Record<string, OpenAiValidationStatus>
  }
}

const OpenAiValidationStatusComponent: React.FC<OpenAiValidationStatusProps> = ({ currentOpenAiValidationLog }) => {
  const getLabel = (key: string) => {
    switch (key) {
      case "ageOK":
        return "Age valide"
      case "fullNameID":
        return "Nom complet sur CNI"
      case "documentType":
        return "Type de document"
      case "expirationOK":
        return "Date d'expiration valide"
      case "addressNumber":
        return "Numéro d'adresse"
      case "birthdateMatch":
        return "Date de naissance concordante"
      case "documentNumber":
        return "Numéro de document"
      case "frontPhotoSide":
        return "Photo de face"
      case "photoVisibility":
        return "Visibilité de la photo"
      case "documentAvailable":
        return "Document disponible"
      case "nameSurnameMatch":
        return "Nom et prénom concordants"
      case "addressInstruction":
        return "Instructions d'adresse"
      default:
        return key
    }
  }

  return (
    <StatusContainer>
      <AIDecisionText>
        {currentOpenAiValidationLog?.status === OpenAiValidationStatus.VALIDATED && (
          <StatusText status="success">Vérifié</StatusText>
        )}
        {currentOpenAiValidationLog?.idcardStatus === OpenAiValidationStatus.REJECTED && (
          <StatusText status="error">CNI KO</StatusText>
        )}
        {currentOpenAiValidationLog?.addressStatus === OpenAiValidationStatus.REJECTED && (
          <StatusText status="error">Adresse KO</StatusText>
        )}
      </AIDecisionText>
      {currentOpenAiValidationLog && (
        <Popup
          trigger={
            <InfoIconWrapper>
              <Icon
                name="info circle"
                style={{
                  color: COLOR.LIGHT_GREY,
                  cursor: "pointer",
                }}
              />
            </InfoIconWrapper>
          }
          content={
            <PopupContent>
              {Object.entries(currentOpenAiValidationLog.explanationToAgent)
                .filter(([key]) => key !== "__typename")
                .map(([key, value]) => (
                  <ValidationItem key={key}>
                    <span>{getLabel(key)} :</span>
                    <ValidationStatus isValid={value === OpenAiValidationStatus.VALIDATED}>
                      {value === OpenAiValidationStatus.VALIDATED ? "OK" : "nOK"}
                    </ValidationStatus>
                  </ValidationItem>
                ))}
            </PopupContent>
          }
          position="top center"
        />
      )}
    </StatusContainer>
  )
}

export default OpenAiValidationStatusComponent
