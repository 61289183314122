import React from "react"
import { COLOR } from "utils/color"
import moment from "moment"
import { KeeperStatus } from "types/keeper.types"

interface KeeperStatusChipProps {
  status: KeeperStatus
  anonymized?: boolean
  anonymizationDate?: string
}

export default function KeeperStatusChip({ status, anonymized, anonymizationDate }: KeeperStatusChipProps) {
  const statuses = [
    {
      value: KeeperStatus.NEW,
      color: COLOR.RED,
      text: "Nouveau",
    },
    {
      value: KeeperStatus.IN_VALIDATION,
      color: COLOR.ORANGE,
      text: "Validation en cours",
    },
    {
      value: KeeperStatus.PENDING,
      color: COLOR.CITRUS_YELLOW,
      text: "En attente",
    },
    {
      value: KeeperStatus.VALIDATED,
      color: COLOR.BRIGHT_MEDIUM_GREEN,
      text: "Validé",
    },
    {
      value: KeeperStatus.REJECTED,
      color: COLOR.DARK_CORAIL,
      text: "Désactivé",
    },
  ]

  const _status = statuses.find(i => i.value === status)

  return (
    <>
      <div className="keeper-status" style={{ backgroundColor: _status.color }}>
        {_status.text}
      </div>
      {anonymized && anonymizationDate && (
        <span style={{ color: COLOR.MEDIUM_SUPER_LIGHT_GREY, fontSize: "10px", marginTop: "5px" }}>
          Anonymisé le {moment(anonymizationDate).format("DD-MM-YYYY")}
        </span>
      )}
    </>
  )
}
