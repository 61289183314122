import React, { ReactElement, useState } from "react"
import { Col, Row } from "reactstrap"
import { Input } from "semantic-ui-react"

import Title from "components/Title"
import { Container, Content, Subtitle } from "components/PushNotifications/PushNotifications.styled"
import { KeepersAvailable, SearchKeepersTab, SelectData } from "components/PushNotifications/push-notifications.types"
import { SearchKeepersByZone } from "components/PushNotifications/SearchKeepersByZone"
import { NotificationContent } from "components/PushNotifications/NotificationContent"
import { SearchKeeperTabs } from "components/PushNotifications/SearchKeeperTabs"

export function PushNotifications(): ReactElement {
  const [statuses, setStatuses] = useState<SelectData[]>([])
  const [zipcodes, setZipcodes] = useState<SelectData[]>([])
  const [keeperIds, setKeepersIds] = useState("")
  const [keepersAvailable, setKeepersAvailable] = useState<KeepersAvailable>({
    loaded: false,
    nbKeepers: null,
    nbKeepersActive: null,
  })

  const [testDone, setTestDone] = useState(false)

  const [selectedSearchKeepersTab, setSelectedSearchKeepersTab] = useState<SearchKeepersTab>(
    SearchKeepersTab.SEARCH_BY_ZONE,
  )

  const handleChangeKeeperIds = (keeperIds: string) => {
    setKeepersIds(keeperIds)
  }

  const handleChangeAvailableKeepers = (keepersAvailable: KeepersAvailable) => setKeepersAvailable(keepersAvailable)

  const handleChangeStatuses = (statuses: SelectData[]) => setStatuses(statuses)

  const handleChangeZipcodes = (statuses: SelectData[]) => setZipcodes(statuses)

  const handleChangeTestDone = (testDone: boolean) => setTestDone(testDone)

  const handleSearchKeepersTab = (searchKeepersTab: SearchKeepersTab) => setSelectedSearchKeepersTab(searchKeepersTab)

  return (
    <Container>
      <Content>
        <Title>📲 Envoi de notifications push</Title>

        <p>
          Envoyer à un ou plusieurs Keepers des notifications push, pour ceux ayant activé leurs notifications push via
          l&apos;application Pickme !
        </p>

        <Row style={{ marginTop: "3em" }}>
          <Col xs={12}>
            <Subtitle>1️⃣ Rechercher les Keepers</Subtitle>
          </Col>
        </Row>

        <SearchKeeperTabs
          selectedSearchKeepersTab={selectedSearchKeepersTab}
          handleSearchKeepersTab={handleSearchKeepersTab}
        />

        {selectedSearchKeepersTab === SearchKeepersTab.SEARCH_BY_ZONE ? (
          <SearchKeepersByZone
            keepersAvailable={keepersAvailable}
            zipcodes={zipcodes}
            statuses={statuses}
            handleChangeAvailableKeepers={handleChangeAvailableKeepers}
            handleChangeStatuses={handleChangeStatuses}
            handleChangeZipcodes={handleChangeZipcodes}
          />
        ) : (
          <Row style={{ marginTop: "1em" }}>
            <Col xs={6} md={6}>
              <Input
                fluid
                placeholder="Liste des IDs"
                onChange={e => handleChangeKeeperIds(e.target.value)}
                className="inputText"
                value={keeperIds}
                disabled={testDone}
              />
            </Col>
          </Row>
        )}

        {(keepersAvailable.loaded && keepersAvailable.nbKeepersActive > 0) ||
        selectedSearchKeepersTab === SearchKeepersTab.SEARCH_BY_KEEPER_IDS ? (
          <NotificationContent
            keepersAvailable={keepersAvailable}
            zipcodes={zipcodes}
            statuses={statuses}
            testDone={testDone}
            keeperIds={keeperIds}
            selectedSearchKeepersTab={selectedSearchKeepersTab}
            handleChangeTestDone={handleChangeTestDone}
            handleChangeAvailableKeepers={handleChangeAvailableKeepers}
            handleChangeKeeperIds={handleChangeKeeperIds}
          />
        ) : (
          <></>
        )}
      </Content>
    </Container>
  )
}

export default PushNotifications
