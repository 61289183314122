import {
  GET_ALL_PAYMENTS,
  GET_DONE_PAYMENTS,
  GET_NEW_PAYMENTS,
  GET_REJECTED_PAYMENTS,
  MOVE_NEW_PAYMENTS_TO_DONE,
  RESET_PAYMENTS,
  REVERT_PAYMENT,
  UPDATE_PAYMENTS,
} from "actions/payments"

interface PaymentPagination {
  limit: number
  cursor?: string
  packmanBatchId?: string
}

export function apiGetNewPayments({ limit = null, cursor = null }: PaymentPagination) {
  return {
    type: GET_NEW_PAYMENTS,
    payload: {
      request: {
        url: `/admin/payments/new?limit=${limit}${cursor ? `&cursor=${cursor}` : ""}`,
      },
    },
  }
}

export function apiGetAllPayments({ limit = null, cursor = null }: PaymentPagination) {
  return {
    type: GET_ALL_PAYMENTS,
    payload: {
      request: {
        url: `/admin/payments/all?limit=${limit}${cursor ? `&cursor=${cursor}` : ""}`,
      },
    },
  }
}

export function apiMoveNewPaymentsToDone(paymentsIds: string[] = null) {
  return {
    type: MOVE_NEW_PAYMENTS_TO_DONE,
    payload: {
      request: {
        method: "PUT",
        url: `/admin/payments/complete`,
        data: { paymentsIds },
      },
    },
  }
}

export function apiRevertPayment({ packmanBatchId, paymentId }) {
  return {
    type: REVERT_PAYMENT,
    payload: {
      request: {
        method: "PUT",
        url: `admin/payments/revert`,
        data: { paymentId, packmanBatchId },
        payload: {
          paymentId,
          packmanBatchId,
        },
      },
    },
  }
}

export function apiGetDonePayments({ limit = null, packmanBatchId = null, cursor = null }: PaymentPagination) {
  return {
    type: GET_DONE_PAYMENTS,
    payload: {
      request: {
        method: "GET",
        url: `/admin/payments/done?limit=${limit}${cursor ? `&cursor=${cursor}` : ""}${
          packmanBatchId ? `&packmanBatchId=${packmanBatchId}` : ""
        }`,
      },
    },
  }
}

export function resetPayments() {
  return {
    type: RESET_PAYMENTS,
  }
}

export function updatePayments({ packmanBatchId = null, paymentId = null }) {
  return {
    type: UPDATE_PAYMENTS,
    payload: { packmanBatchId, paymentId },
  }
}

export function apiGetRejectedPayments({ limit = null, cursor = null }) {
  return {
    type: GET_REJECTED_PAYMENTS,
    payload: {
      request: {
        method: "GET",
        url: `/admin/payments/pendingBAUpdate?limit=${limit}${cursor ? `&cursor=${cursor}` : ""}`,
      },
    },
  }
}

export function apiRejectAndRequireBAUpdate(paymentId: string) {
  return {
    type: GET_REJECTED_PAYMENTS,
    payload: {
      request: {
        method: "PUT",
        url: "/admin/payments/rejectAndRequireBAUpdate",
        data: {
          paymentId,
        },
      },
    },
  }
}
