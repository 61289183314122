import { KeeperStatus } from "types/keeper.types"

export enum AdminKeeperEventType {
  CLEAR_AGENDA = "CLEAR_AGENDA",
  REJECT_KEEPER = "REJECT_KEEPER",
  REACTIVATE_KEEPER = "REACTIVATE_KEEPER",
  ASK_TRANSFER = "ASK_TRANSFER",
  ASK_DONATION = "ASK_DONATION",
  ASK_GIVEAWAY = "ASK_GIVEAWAY",
  ANONYMIZE_KEEPER = "ANONYMIZE_KEEPER",
}

export interface AdminKeeperAction {
  key: number
  text: string
  value: AdminKeeperEventType
  statuses: KeeperStatus[]
  reasons: { label: string; value: string }[]
}

export const KEEPER_ACTIONS: AdminKeeperAction[] = [
  {
    key: 1,
    text: "Vider l'agenda",
    value: AdminKeeperEventType.CLEAR_AGENDA,
    statuses: [
      KeeperStatus.NEW,
      KeeperStatus.PENDING,
      KeeperStatus.REJECTED,
      KeeperStatus.IN_VALIDATION,
      KeeperStatus.VALIDATED,
    ],
    reasons: [],
  },
  {
    key: 2,
    text: "Désactiver le Keeper",
    value: AdminKeeperEventType.REJECT_KEEPER,
    statuses: [KeeperStatus.NEW, KeeperStatus.PENDING, KeeperStatus.IN_VALIDATION, KeeperStatus.VALIDATED],
    reasons: [],
  },
  {
    key: 3,
    text: "Réactiver le Keeper",
    value: AdminKeeperEventType.REACTIVATE_KEEPER,
    statuses: [KeeperStatus.REJECTED],
    reasons: [],
  },
  {
    key: 4,
    text: "Anonymiser le Keeper",
    value: AdminKeeperEventType.ANONYMIZE_KEEPER,
    statuses: [KeeperStatus.REJECTED],
    reasons: [],
  },
]
