import React, { useState } from "react"
import { Button, Col, Row } from "reactstrap"
import { HelpInputText, Subtitle } from "./PushNotifications.styled"
import { Dropdown, Input } from "semantic-ui-react"
import Search from "../SearchBar/SearchBar"
import { deeplinkToDashboard, deeplinkPrefix } from "./push-notifications.data"
import {
  apiSearchTestKeepers,
  apiSendPushNotifications,
  apiSendPushNotificationsToKeeperIds,
  apiSendTestPushNotification,
} from "services/push-notifications"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import { KeepersAvailable, SearchKeepersTab, SelectData } from "./push-notifications.types"
import { formatKeeperIdsFromStringToArray } from "utils/input"

// values are deeplinks configurated on keeper-app for expo-notifications
const notificationRedirectionOptions = [
  { key: "Dashboard", text: "Dashboard", value: deeplinkToDashboard },
  { key: "Chat", text: "Chat", value: `${deeplinkPrefix}://Chat` },
  { key: "Sponsor", text: "Sponsor", value: `${deeplinkPrefix}://Sponsorship` },
]

interface NotificationContentProps {
  testDone: boolean
  keepersAvailable: KeepersAvailable
  statuses: SelectData[]
  zipcodes: SelectData[]
  keeperIds: string
  selectedSearchKeepersTab: SearchKeepersTab
  handleChangeAvailableKeepers: (keepersAvailable: KeepersAvailable) => void
  handleChangeTestDone: (testDone: boolean) => void
  handleChangeKeeperIds: (keeperIds: string) => void
}

export const NotificationContent = ({
  testDone,
  keepersAvailable,
  statuses,
  zipcodes,
  keeperIds,
  selectedSearchKeepersTab,
  handleChangeTestDone,
  handleChangeAvailableKeepers,
  handleChangeKeeperIds,
}: NotificationContentProps) => {
  const dispatch = useDispatch()

  const [notificationSent, setNotificationSent] = useState(false)
  const [notificationData, setNotificationData] = useState({
    title: "",
    content: "",
    data: { redirectTo: deeplinkToDashboard },
  })
  const [testKeeperId, setTestKeeperId] = useState<string>(null)

  /**
   * Get test Keepers to test this notification
   * @param input - Search input
   * @returns List of Keepers
   */
  const getTestKeepers = async (input: string) => {
    if (input.length > 2) {
      const r = await dispatch(apiSearchTestKeepers({ input }))
      return r.payload.data.map(k => ({
        value: k.id,
        label: `😎 ${k.firstName} ${k.lastName} (${k.email})`,
        type: "KEEPER",
      }))
    }
    return []
  }

  const _onChangeKeepers = (keeper: { label: string; type: string; value: string }) => {
    setTestKeeperId(keeper.value)
  }

  /**
   * Send test notification to the test Keeper.
   * Will disable the notification content edition.
   */
  const sendTestNotification = async () => {
    try {
      const r = await dispatch(apiSendTestPushNotification({ keeperId: testKeeperId, ...notificationData }))
      const success = r.payload?.data

      if (!success) throw Error("Error while sending test notification")

      handleChangeTestDone(true)
    } catch (e) {
      handleChangeTestDone(false)
    }
  }

  /**
   * Send a rocket by notifying every Keeper concerned.
   */
  const sendPushNotifications = async () => {
    setNotificationSent(true)

    if (keeperIds) {
      await dispatch(
        apiSendPushNotificationsToKeeperIds({
          keeperIds: formatKeeperIdsFromStringToArray(keeperIds),
          ...notificationData,
        }),
      )
    } else {
      await dispatch(
        apiSendPushNotifications({
          statuses: statuses.map(s => s.value),
          zipcodes: zipcodes.map(z => z.value),
          ...notificationData,
        }),
      )
    }

    toast.success("Notification envoyée !")

    // Full reset of the form
    handleChangeAvailableKeepers({
      loaded: false,
      nbKeepers: null,
      nbKeepersActive: null,
    })
    setTestKeeperId(null)
    handleChangeTestDone(false)
    setNotificationData({ title: "", content: "", data: { redirectTo: "" } })
    handleChangeKeeperIds("")
  }

  const resetForm = () => {
    setNotificationData({ title: "", content: "", data: { redirectTo: "" } })
    setTestKeeperId(null)
    handleChangeTestDone(false)
  }

  const displayTestKeeperNotifSection = () => {
    const hasTitle = Boolean(notificationData.title)
    const hasContent = Boolean(notificationData.content)
    const requiresKeepers = selectedSearchKeepersTab === SearchKeepersTab.SEARCH_BY_KEEPER_IDS
    const hasKeepers = !requiresKeepers || Boolean(keeperIds.length)

    return hasTitle && hasContent && hasKeepers
  }

  const isTestKeeperNotifSectionDisplayed = displayTestKeeperNotifSection()

  return (
    <>
      <Row style={{ marginTop: 20 }}>
        <Col xs={6} style={{ position: "relative" }}>
          <Subtitle>2️⃣ Notification</Subtitle>
          <Input
            fluid
            placeholder="Exemple : Annonce incroyable 🤩"
            onChange={e => setNotificationData({ ...notificationData, title: e.target.value })}
            className="inputText"
            label={"Titre"}
            value={notificationData.title}
            disabled={testDone}
          />
          <HelpInputText style={{ marginBottom: 10 }}>
            {notificationData.title.length}/30 caractères max recommandés
          </HelpInputText>
          <Input
            fluid
            placeholder="Exemple : Un nouveau transporteur arrive dans votre quartier !"
            onChange={e => setNotificationData({ ...notificationData, content: e.target.value })}
            className="inputText"
            label={"Contenu"}
            value={notificationData.content}
            disabled={testDone}
          />
          <HelpInputText>{notificationData.content.length}/140 caractères max recommandés</HelpInputText>

          <Dropdown
            fluid
            selection
            defaultValue={deeplinkToDashboard}
            options={notificationRedirectionOptions}
            onChange={(e, { value }) => {
              setNotificationData({
                ...notificationData,
                // toString to handle type error that prevents build
                data: { redirectTo: value.toString() },
              })
            }}
            style={{ marginTop: 20 }}
            disabled={testDone}
          />
        </Col>
      </Row>

      {isTestKeeperNotifSectionDisplayed ? (
        <>
          <Row style={{ marginTop: 20 }}>
            <Col xs={6}>
              <Subtitle>3️⃣ Tester la notification</Subtitle>
              Pour tester la notification, il vous faut un compte Keeper connecté sur l&apos;application Pickme, dont
              l&apos;email est une adresse <b>@mypickme.com</b> et avec les notifications push activées !
              <br />
              Si c&apos;est bon, tapez le nom du Keeper pour le trouver ci-dessous
              <br />
              <br />
              <Search
                onSearch={getTestKeepers}
                onChange={_onChangeKeepers}
                placeholder={"🔍 Rechercher un Keeper de test"}
              />
              <br />
            </Col>
            <Col xs={6} style={{ display: "flex", alignItems: "center" }}>
              {testDone && `✅ Notification envoyée`}
            </Col>
          </Row>
          <Button
            disabled={!testKeeperId || !notificationData.title || !notificationData.content}
            onClick={() => sendTestNotification()}
          >
            {testDone ? "Retester" : "Tester"}
          </Button>
          <Button style={{ marginLeft: 10 }} onClick={() => resetForm()} color="red">
            Recommencer
          </Button>
        </>
      ) : (
        <></>
      )}

      {testDone && (
        <>
          <Row style={{ marginTop: 20 }}>
            <Col xs={12}>
              <Subtitle>4️⃣ Validation</Subtitle>
              Tout semble prêt pour envoyer la 🚀
            </Col>
          </Row>
          <br />
          {!notificationSent && (
            <Button onClick={() => sendPushNotifications()}>
              Envoyer la notification à {keepersAvailable.nbKeepersActive} Keepers
            </Button>
          )}
        </>
      )}
    </>
  )
}
