export enum ParcelEventCode {
  PARCEL_CREATED = "PARCEL_CREATED",
  /** CARRIER EVENTS */
  /** BtoC Delivery */
  CARRIER_BTOC_DELIVERY_INIT = "CARRIER_BTOC_DELIVERY_INIT",
  CARRIER_BTOC_DELIVERY_PICKUP_SUCCESS = "CARRIER_BTOC_DELIVERY_PICKUP_SUCCESS",
  CARRIER_BTOC_DELIVERY_PICKUP_FAILURE = "CARRIER_BTOC_DELIVERY_PICKUP_FAILURE",
  CARRIER_BTOC_DELIVERY_IN_TRANSIT = "CARRIER_BTOC_DELIVERY_IN_TRANSIT",
  CARRIER_BTOC_DELIVERY_OUT_FOR_DELIVERY = "CARRIER_BTOC_DELIVERY_OUT_FOR_DELIVERY",
  CARRIER_BTOC_DELIVERY_DELIVERY_SUCCESS = "CARRIER_BTOC_DELIVERY_DELIVERY_SUCCESS", // old "onCarrierDeliverySuccess"
  CARRIER_BTOC_DELIVERY_DELIVERY_FAILURE = "CARRIER_BTOC_DELIVERY_DELIVERY_FAILURE", // old "onCarrierDeliveryFailure"
  CARRIER_BTOC_DELIVERY_RETURNED_TO_SENDER = "CARRIER_BTOC_DELIVERY_RETURNED_TO_SENDER",
  CARRIER_BTOC_DELIVERY_DELIVERY_CANCELLED = "CARRIER_BTOC_DELIVERY_DELIVERY_CANCELLED", // old "onParcelCancelled"
  CARRIER_BTOC_DELIVERY_PARCEL_LOST = "CARRIER_BTOC_DELIVERY_PARCEL_LOST", // old "onParcelLost"
  /** BtoC Return */
  CARRIER_BTOC_RETURN_INIT = "CARRIER_BTOC_RETURN_INIT",
  CARRIER_BTOC_RETURN_PICKUP_SUCCESS = "CARRIER_BTOC_RETURN_PICKUP_SUCCESS",
  CARRIER_BTOC_RETURN_PICKUP_FAILURE = "CARRIER_BTOC_RETURN_PICKUP_FAILURE",
  CARRIER_BTOC_RETURN_IN_TRANSIT = "CARRIER_BTOC_RETURN_IN_TRANSIT",
  CARRIER_BTOC_RETURN_OUT_FOR_DELIVERY = "CARRIER_BTOC_RETURN_OUT_FOR_DELIVERY", // old "outForDelivery"
  CARRIER_BTOC_RETURN_DELIVERY_SUCCESS = "CARRIER_BTOC_RETURN_DELIVERY_SUCCESS",
  CARRIER_BTOC_RETURN_DELIVERY_FAILURE = "CARRIER_BTOC_RETURN_DELIVERY_FAILURE",
  CARRIER_BTOC_RETURN_PICKUP_CANCELLED = "CARRIER_BTOC_RETURN_PICKUP_CANCELLED",
  CARRIER_BTOC_RETURN_PARCEL_LOST = "CARRIER_BTOC_RETURN_PARCEL_LOST",
  /** CtoB Delivery */
  CARRIER_CTOB_DELIVERY_PICKUP_SUCCESS = "CARRIER_CTOB_DELIVERY_PICKUP_SUCCESS",
  CARRIER_CTOB_DELIVERY_PICKUP_FAILURE = "CARRIER_CTOB_DELIVERY_PICKUP_FAILURE",
  /** CTOB EVENTS */
  DROPOFF_MEETING_UPDATE = "DROPOFF_MEETING_UPDATE",
  COLLECT_MEETING_UPDATE = "COLLECT_MEETING_UPDATE",
  /** KEEPER EVENTS */
  KEEPER_RECEPTION_SUCCESS = "KEEPER_RECEPTION_SUCCESS", // old "onKeeperDeliverySuccess"
  KEEPER_RECEPTION_FAILURE = "KEEPER_RECEPTION_FAILURE", // old "onKeeperDeliveryFailure"
  KEEPER_RECIPIENT_DELIVERY_SUCCESS = "KEEPER_RECIPIENT_DELIVERY_SUCCESS", // old "onRecipientDeliverySuccess"
  KEEPER_RECIPIENT_DELIVERY_FAILURE = "KEEPER_RECIPIENT_DELIVERY_FAILURE", // old "onRecipientDeliveryFailure"
  KEEPER_RETURNING_SUCCESS = "KEEPER_RETURNING_SUCCESS", // old onKeeperReturningSuccess
  KEEPER_RETURNING_FAILURE = "KEEPER_RETURNING_FAILURE",
  KEEPER_DROPOFF_SUCCESS = "KEEPER_DROPOFF_SUCCESS",
  KEEPER_DROPOFF_FAILURE = "KEEPER_DROPOFF_FAILURE",
  KEEPER_DROPOFF_DELIVERY_SUCCESS = "KEEPER_DROPOFF_DELIVERY_SUCCESS",
  KEEPER_DROPOFF_DELIVERY_FAILURE = "KEEPER_DROPOFF_DELIVERY_FAILURE",
  // RETURNS EVENTS
  PARCEL_RETURN_TO_PLAN = "PARCEL_RETURN_TO_PLAN", // old "ON_RETURN_TO_PLAN"
  PARCEL_RETURN_REQUESTED = "PARCEL_RETURN_REQUESTED", // old "onReturnRequested"
  PARCEL_RETURN_READY_TO_PICKUP = "PARCEL_RETURN_READY_TO_PICKUP", // old "ON_RETURN_READY_TO_PICKUP"
  PARCEL_RETURN_COMPLETED = "PARCEL_RETURN_COMPLETED", // old "onReturnCompleted"
  PARCEL_RETURN_NOT_COMPLETED = "PARCEL_RETURN_NOT_COMPLETED",
  // NOTIFICATIONS EVENTS
  MAIL_NOTICE = "MAIL_NOTICE", // old "onMailNotice"
  SMS_NOTICE = "SMS_NOTICE", // old "onSmsNotice"
  KEEPER_MAIL_NOTICE = "KEEPER_MAIL_NOTICE",
  KEEPER_SMS_NOTICE = "KEEPER_SMS_NOTICE",
  KEEPER_PUSH_NOTICE = "KEEPER_PUSH_NOTICE",
  CARRIER_MAIL_NOTICE = "CARRIER_MAIL_NOTICE",
  CARRIER_CONTACT_MAIL_NOTICE = "CARRIER_CONTACT_MAIL_NOTICE",
  CARRIER_CONTACT_MAIL_NOTICE_FAILURE = "CARRIER_CONTACT_MAIL_NOTICE_FAILURE",
  // Others
  PARCEL_REASSIGNED = "PARCEL_REASSIGNED", // old "onReassignmment"
  PARCEL_CANCELLED = "PARCEL_CANCELLED", // old "onCancellation"
  PARCEL_UNCANCELLED = "PARCEL_UNCANCELLED",
  PARCEL_SLEEPING_J1 = "PARCEL_SLEEPING_J1",
  PARCEL_SLEEPING_J3 = "PARCEL_SLEEPING_J3",
  PARCEL_SLEEPING_J7 = "PARCEL_SLEEPING_J7",
  PARCEL_SLEEPING = "PARCEL_SLEEPING", // old "sleepingParcel"
  PARCEL_SLEEPING_MISSED_APPOINTMENT = "PARCEL_SLEEPING_MISSED_APPOINTMENT", // parcel event service
  PARCEL_UNSCANNED = "PARCEL_UNSCANNED", // old "keeperUnscannedParcel"
  PARCEL_NEEDS_REASSIGNMENT = "PARCEL_NEEDS_REASSIGNMENT", // old "keeperUnscannedParcel"
  KEEPER_CONFIRMED_FOR_TOMORROW = "KEEPER_CONFIRMED_FOR_TOMORROW",
  PARCEL_INSTANCE_OVER = "PARCEL_INSTANCE_OVER",
  PARCEL_DROPPED_UNSCANNED = "PARCEL_DROPPED_UNSCANNED",
  PARCEL_CARRIER_LONG_RETURN = "PARCEL_CARRIER_LONG_RETURN",
  KEEPER_CLICK_CONTACT = "KEEPER_CLICK_CONTACT",
  PARCEL_UNSCANNED_NOT_RECEIVED_BY_KEEPER = "PARCEL_UNSCANNED_NOT_RECEIVED_BY_KEEPER",
  PARCEL_PERSONAL_DATA_ANONYMIZED = "PARCEL_PERSONAL_DATA_ANONYMIZED",
  PARCEL_ARCHIVED = "PARCEL_ARCHIVED",
  // Admin events
  PARCEL_BACK_IN_TRANSIT = "PARCEL_BACK_IN_TRANSIT",
  CANCEL_RETURN_REQUESTED = "CANCEL_RETURN_REQUESTED",
  CANCEL_KEEPER_RECEPTION_SUCCESS = "CANCEL_KEEPER_RECEPTION_SUCCESS",
  CANCEL_RETURN_TO_PLAN = "CANCEL_RETURN_TO_PLAN",
  CANCEL_KEEPER_RETURNING_SUCCESS = "CANCEL_KEEPER_RETURNING_SUCCESS",
  // Special Events
  DHL_PARCEL_STILL_WITH_KEEPER = "DHL_PARCEL_STILL_WITH_KEEPER",
  KEEPER_DELIVERY_SERVICE_ON = "KEEPER_DELIVERY_SERVICE_ON",
  PARCEL_BILLING_UPDATED = "PARCEL_BILLING_UPDATED",
  // custom events
  RECIPIENT_CONNECTED_ON_CHAT = "RECIPIENT_CONNECTED_ON_CHAT",
  RECIPIENT_CLICK_SUPPORT_LINK = "RECIPIENT_CLICK_SUPPORT_LINK",
  PARCEL_FLAG_ADDED = "PARCEL_FLAG_ADDED",
  PARCEL_FLAG_REMOVED = "PARCEL_FLAG_REMOVED",
}

export enum ParcelEventReason {
  REFUSED_BY_KEEPER = "REFUSED_BY_KEEPER",
  REFUSED_BY_RECIPIENT = "REFUSED_BY_RECIPIENT",
  PARCEL_DAMAGED = "PARCEL_DAMAGED",
  PARCEL_TRACKING_LOST = "PARCEL_TRACKING_LOST",
  KEEPER_ABSENT = "KEEPER_ABSENT",
  MISSING_ADDRESS_DATA = "MISSING_ADDRESS_DATA",
  CARRIER_OUT_OF_TIME = "CARRIER_OUT_OF_TIME",
  KEEPER_DID_NOT_SCAN = "KEEPER_DID_NOT_SCAN",
  DELIVERED_TO_PICKUP_POINT = "DELIVERED_TO_PICKUP_POINT",
  DELIVERED_TO_RECIPIENT = "DELIVERED_TO_RECIPIENT",
  DELIVERY_OUT_OF_KEEPER_AVAILABILITIES = "DELIVERY_OUT_OF_KEEPER_AVAILABILITIES",
  KEEPER_CHANGED_AVAILABILITIES = "KEEPER_CHANGED_AVAILABILITIES",
  OTHER = "OTHER",
  RETURN_TO_SHOP = "RETURN_TO_SHOP",
  NO_ANNOUNCEMENT = "NO_ANNOUNCEMENT",
  MANUAL_CREATION_OTHER = "MANUAL_CREATION_OTHER",
  RECIPIENT_ABSENT = "RECIPIENT_ABSENT",
  // MEETING UPDATE REASONS
  KEEPER_NOT_AVAILABLE = "KEEPER_NOT_AVAILABLE",
  CANCELLED_BY_FINAL_CLIENT = "CANCELLED_BY_FINAL_CLIENT",
  UPDATED_BY_FINAL_CLIENT = "UPDATED_BY_FINAL_CLIENT",
  CANCELLED_BY_CARRIER = "CANCELLED_BY_CARRIER",
  UPDATED_BY_CARRIER = "UPDATED_BY_CARRIER",
  MISSED_BY_KEEPER = "MISSED_BY_KEEPER",
  MISSED_BY_FINAL_CLIENT = "MISSED_BY_FINAL_CLIENT",
  RETURNED_TO_BP = "RETURNED_TO_BP",
}
