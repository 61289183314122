import styled from "styled-components"

export const TransferDate = styled.p`
  font-size: 12px;
`

export const TotalSum = styled.p`
  font-size: 12px;
`

export const ButtonContainer = styled.div`
  margin-top: 10px;
`
