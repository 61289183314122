export interface KeepersAvailable {
  loaded: boolean
  nbKeepers: number
  nbKeepersActive: number
}

export type SelectData = {
  value: string
  label: string
}

export enum SearchKeepersTab {
  SEARCH_BY_ZONE = "SEARCH_BY_ZONE",
  SEARCH_BY_KEEPER_IDS = "SEARCH_BY_KEEPER_IDS",
}
