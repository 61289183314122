import React, { ReactElement } from "react"
import styled from "styled-components"

import { KeeperCheckResult } from "types/keeper.types"
import { COLOR } from "utils/color"

const KeeperCheckChipContainer = styled.div<{ disabled: boolean; active: boolean; color: string }>`
  background-color: ${({ active, color }) => (active ? color : "transparent")};
  font-family: Poppins-Medium;
  font-size: 10px;
  padding: 5px 15px;
  border-radius: 20px;
  color: ${({ disabled, active, color }) => (disabled ? COLOR.LIGHT_GREY : active ? COLOR.WHITE : color)};
  border: ${({ disabled, color }) => `1px solid ${disabled ? COLOR.LIGHT_GREY : color}`};
  cursor: pointer;
  margin-right: 2px;
`

interface KeeperCheckChipProps {
  checkResult: KeeperCheckResult
  disabled: boolean
  active: boolean
  onClick: () => void
}

function KeeperCheckChip({ checkResult, disabled, active, onClick }: KeeperCheckChipProps): ReactElement {
  const getLabel = (checkResult: KeeperCheckResult) => {
    switch (checkResult) {
      case KeeperCheckResult.VALIDATED:
        return "Vérifié"
      case KeeperCheckResult.ADDRESS_REJECTED:
        return "Adresse KO"
      case KeeperCheckResult.IDCARD_REJECTED:
        return "CNI KO"
    }
  }

  const getColor = (checkResult: KeeperCheckResult) => {
    switch (checkResult) {
      case KeeperCheckResult.VALIDATED:
        return COLOR.MEDIUM_LIGHT_GREEN
      case KeeperCheckResult.ADDRESS_REJECTED:
        return COLOR.CORAIL
      case KeeperCheckResult.IDCARD_REJECTED:
        return COLOR.CORAIL
      default:
        return COLOR.BLACK
    }
  }

  return (
    <KeeperCheckChipContainer
      color={getColor(checkResult)}
      disabled={disabled}
      active={active}
      onClick={disabled ? () => true : onClick}
    >
      {getLabel(checkResult)}
    </KeeperCheckChipContainer>
  )
}

export default KeeperCheckChip
