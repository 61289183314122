import React, { ReactElement, useEffect, useState } from "react"
import { Button, Loader, Modal, Search } from "semantic-ui-react"
import styled from "styled-components"
import { useDispatch } from "react-redux"

import LineItemReassign from "components/Parcels/Reassignment/LineItemReassign"
import LineCollapsableElement from "components/List/LineCollapsableElement"
import FilterKeeperToReassign from "components/Parcels/Reassignment/FilterKeeperToReassign"
import { apiGetKeepersToReassign, apiSearchKeepersToReassign, resetKeepersToReassign } from "services/parcels"
import { Parcel, ParcelReassign, ParcelStatus } from "types/parcel.types"
import { checkAgenda } from "utils/agenda"
import { COLOR } from "utils/color"
import { CarriersName } from "types/carrier.types"
import { ConfirmReroutingParcelToBp } from "./ConfirmReroutingParcelToBP/ConfirmReroutingParcelToBP"

const ModalLoader = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 10;
`

const ModalContent = styled.div`
  background: white;
  border-radius: 30px;
  padding: 40px;

  height: 100%;
  display: flex;
  flex-direction: column;
`

const SeparatorBold = styled.hr`
  margin-left: -40px;
  margin-right: -40px;
  border-width: 2px;
  margin-bottom: 0;
`

const ModalHeader = styled.div`
  padding-bottom: 25px;
  flex: 1;
  display: flex;
  flex-direction: row;
`

const ModalHeaderSplit = styled.div`
  flex: 1;
  flex-direction: column;
`

const ModalTitle = styled.div`
  border-radius: 30px;
  font-size: 20px;
`

const ModalDescription = styled.div`
  margin-top: 15px;
`

const ModalDescriptionData = styled.span`
  margin-left: 5px;
`

const ModalData = styled.div`
  margin-left: -30px;
  margin-right: -30px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 10;
`

const ModalBottom = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  flex: 1;
`

const ReroutingBPButton = styled.button`
  display: block;
  justify-self: start;
  color: ${COLOR.MEDIUM_BLUE};
  text-decoration: underline;
  text-decoration-color: ${COLOR.MEDIUM_BLUE};
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin-right: auto;
`

const WarningMessage = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  color: ${COLOR.CORAIL};
`

const filterAvailabilities = {
  startDate: new Date().toString(),
  numberDaysToCheck: 3,
  startHour: "09:00",
  endHour: "18:00",
  numberOfAvailabilitiesNeeded: 2,
}

interface AdminReassignModalProps {
  reassignModalData: { opened: boolean; parcel: Parcel | null; carrierName: string }
  cancelAdminReassignModal: () => void
  confirmAdminReassignModal: (
    addressId: string,
    newKeeperInfos: {
      name: string
      address: string
      instructions: string
      phone: string
    },
  ) => void
  submitForceReturnToBP: () => Promise<void>
  keepersToReassign: ParcelReassign[]
  pageInfos: {
    hasNextPage: boolean
    endCursor: string
  }
  loading: boolean
}

const AdminReassignModal = ({
  reassignModalData,
  cancelAdminReassignModal,
  confirmAdminReassignModal,
  submitForceReturnToBP,
  pageInfos,
  keepersToReassign,
  loading,
}: AdminReassignModalProps): ReactElement => {
  const dispatch = useDispatch()
  const first = 5
  const [sortByKeeperAvailable, setSortByKeeperAvailable] = useState(false)
  const [keeperAvailableToReassign, setKeeperAvailableToReassign] = useState(keepersToReassign)
  const [delaySearch, setDelaySearch] = useState({ timeout: setTimeout(() => {}, 0) })
  const [isConfirmReroutingParcelToBPOpened, setIsConfirmReroutingParcelToBPOpened] = useState<boolean>(false)

  const { parcel, carrierName } = reassignModalData
  const displayManualReassignmentWarning = carrierName === "DHL"

  useEffect(() => {
    if (reassignModalData.opened) {
      dispatch(resetKeepersToReassign())

      if (parcel && parcel.id) {
        dispatch(apiGetKeepersToReassign(parcel.id, first, ""))
      }
    }
  }, [reassignModalData.opened])

  useEffect(() => {
    if (sortByKeeperAvailable) {
      return setKeeperAvailableToReassign(
        keepersToReassign.filter(item => checkAgenda(item.node.address.agenda, filterAvailabilities)),
      )
    } else setKeeperAvailableToReassign(keepersToReassign)
  }, [sortByKeeperAvailable, keepersToReassign])

  if (!parcel) {
    return (
      <Modal open={reassignModalData.opened} className="reassign-modal">
        <ModalContent>
          <ModalTitle>Aucun colis détecté</ModalTitle>
          <ModalDescription />
        </ModalContent>
      </Modal>
    )
  }

  const fetchNext = () => {
    if (pageInfos.hasNextPage) {
      dispatch(apiGetKeepersToReassign(parcel.id, first, pageInfos.endCursor))
    }
  }

  const _onSearchKeepers = (searchText: string) => {
    if (searchText.length > 2) {
      if (delaySearch.timeout) {
        clearTimeout(delaySearch.timeout)
      }

      setDelaySearch({
        timeout: setTimeout(() => {
          dispatch(apiSearchKeepersToReassign(searchText, parcel.id))
        }, 700),
      })
    } else if (searchText.length === 0) {
      if (delaySearch.timeout) {
        clearTimeout(delaySearch.timeout)
      }

      setDelaySearch({
        timeout: setTimeout(() => {
          dispatch(apiGetKeepersToReassign(parcel.id, first, ""))
        }, 700),
      })
    }
  }

  const closeModal = () => {
    setSortByKeeperAvailable(false)
    cancelAdminReassignModal()
  }

  const handleSortByKeeperAvailable = () => {
    setSortByKeeperAvailable(!sortByKeeperAvailable)
  }

  const confirmationAction = (addressId: string, newKeeperInfos: any) => {
    confirmAdminReassignModal(addressId, newKeeperInfos)
  }

  const handleOpenConfirmReroutingParcelToBP = () => setIsConfirmReroutingParcelToBPOpened(true)

  const handleCloseConfirmReroutingParcelToBP = () => setIsConfirmReroutingParcelToBPOpened(false)

  return (
    <Modal open={reassignModalData.opened} className="reassign-modal">
      <ModalContent>
        <ModalHeader>
          <ModalHeaderSplit>
            <ModalTitle>Choisir un Keeper pour réassigner le colis</ModalTitle>
            <ModalDescription>
              📦 <ModalDescriptionData>{parcel.parcelNo}</ModalDescriptionData>
            </ModalDescription>
          </ModalHeaderSplit>
          <ModalHeaderSplit>
            <Search
              onSearchChange={(_, data) => _onSearchKeepers(data.value)}
              showNoResults={false}
              className="reassign-search"
              placeholder="Chercher un Keeper"
            />
          </ModalHeaderSplit>
        </ModalHeader>

        {displayManualReassignmentWarning && (
          <WarningMessage>{"⚠️ Un mail devra être envoyé à l'agence pour valider la réassignation"}</WarningMessage>
        )}

        <FilterKeeperToReassign
          isFilteredByKeeperAvailable={sortByKeeperAvailable}
          handleFilterByKeeperAvailable={handleSortByKeeperAvailable}
        />
        <SeparatorBold />

        <ModalData>
          {(!keeperAvailableToReassign || keeperAvailableToReassign.length === 0) && (
            <p style={{ margin: 10, textAlign: "center" }}>Aucun Keeper trouvé</p>
          )}
          {keeperAvailableToReassign &&
            keeperAvailableToReassign.length > 0 &&
            keeperAvailableToReassign.map((value, index) => (
              <LineCollapsableElement
                key={`linecollapsible-reassign-parcel-${index}`}
                collapseId={null}
                element={value.node.address}
                lineHeight="sm reassign-row"
                lineData={
                  <LineItemReassign
                    address={value.node.address}
                    confirmationAction={(
                      addressId: string,
                      newKeeperInfos: {
                        name: string
                        address: string
                        instructions: string
                        phone: string
                      },
                    ) => confirmationAction(addressId, newKeeperInfos)}
                  />
                }
                collapsibleData={null}
              />
            ))}
          {loading && (
            <ModalLoader>
              <Loader className="black-loader" inline active />
            </ModalLoader>
          )}
          {pageInfos?.hasNextPage && keeperAvailableToReassign && keeperAvailableToReassign.length > 0 && (
            <Button style={{ marginTop: 10 }} onClick={fetchNext}>
              Charger plus
            </Button>
          )}
        </ModalData>
        <ModalBottom>
          {parcel?.order?.__client__?.name === CarriersName.COLISSIMO &&
            [ParcelStatus.IN_DELIVERY, ParcelStatus.IN_TRANSIT].includes(parcel.status) && (
              <ReroutingBPButton onClick={handleOpenConfirmReroutingParcelToBP}>
                Demander un reroutage en BP
              </ReroutingBPButton>
            )}
          <Button style={{ marginLeft: "auto" }} color="black" onClick={closeModal}>
            Annuler
          </Button>
        </ModalBottom>
      </ModalContent>
      <ConfirmReroutingParcelToBp
        isOpened={isConfirmReroutingParcelToBPOpened}
        onClose={handleCloseConfirmReroutingParcelToBP}
        onSubmit={submitForceReturnToBP}
      />
    </Modal>
  )
}

export default AdminReassignModal
