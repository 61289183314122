import { AdminParcelEventReason, Parcel } from "./parcel.types"
import { Company } from "./company.types"
import { AvailabilityRecurring } from "./agenda.types"

export enum BtocReturnRequestFormStatus {
  WAITING_FOR_KEEPER_INFOS = "WAITING_FOR_KEEPER_INFOS",
  WAITING_FOR_CARE_VALIDATION = "WAITING_FOR_CARE_VALIDATION",
  COMPLETED = "COMPLETED",
}

export interface BtocReturnRequestForm {
  id: string
  parcelId: string
  parcel: Parcel
  returningCarrierId: string
  returningCarrier: Company
  collectOptionsStartHour: string
  collectOptionsEndHour: string
  collectOptionsAvailableDays?: AvailabilityRecurring[]
  reason?: string
  pickupDate?: Date
  interval?: {
    start: string
    end: string
  }
  dimensions?: string
  weight?: number
  cotransportationCode?: string
  referenceCode?: string
  status: BtocReturnRequestFormStatus
  companyAddress: {
    deliveryAddressId: string
    deliveryAddressName: string
    deliveryAddress: string
    deliveryPhone?: string
    deliveryInstructions: string
    deliveryAddressZipcode: string
    deliveryAddressCity: string
    latitude: string
    longitude: string
  }
  createdAt: Date
  updatedAt: Date
}

export interface RRFaddress {
  deliveryAddressId: string
  deliveryAddressName: string
  deliveryAddress: string
  deliveryPhone: string
  deliveryInstructions: string
  deliveryAddressZipcode: string
  deliveryAddressCity: string
  latitude: string
  longitude: string
}

export interface ReturnRequestFormData {
  isAlreadyCollected: boolean
  isAlreadyOrderedToReturningCarrier: boolean
  returningCarrier: { label: string; value: string; data: Company } | null
  pickupDate: string
  timeIntervalStart: { label: string; value: string } | null
  timeIntervalEnd: { label: string; value: string } | null
  cotransportationCode: string
  referenceCode: string
  parcelDimensions: { label: string; value: string } | null
  returnReason: { label: string; value: AdminParcelEventReason } | null
  returnReasonDetails: string
  deliveryAddressId: string
  deliveryAddressName: string
  deliveryAddress: string
  deliveryPhone: string
  deliveryInstructions: string
  deliveryAddressZipcode: string
  deliveryAddressCity: string
  deliveryAddressLatitude: string
  deliveryAddressLongitude: string
}

export enum ReturnRequestFieldKeys {
  isAlreadyCollected = "isAlreadyCollected",
  isAlreadyOrderedToReturningCarrier = "isAlreadyOrderedToReturningCarrier",
  returningCarrier = "returningCarrier",
  returnReason = "returnReason",
  pickupDate = "pickupDate",
  timeIntervalStart = "timeIntervalStart",
  timeIntervalEnd = "timeIntervalEnd",
  referenceCode = "referenceCode",
  cotransportationCode = "cotransportationCode",
  parcelDimensions = "parcelDimensions",
  deliveryInstructions = "deliveryInstructions",
  deliveryPhone = "deliveryPhone",
  deliveryAddress = "deliveryAddress",
  deliveryAddressName = "deliveryAddressName",
  returnReasonDetails = "returnReasonDetails",
}
