import styled from "styled-components"
import { COLOR } from "utils/color"

export const Container = styled.div`
  flex: 1;
  padding: 10px;
`

export const Content = styled.div`
  padding: 30px;
`

export const LabelInput = styled.div`
  font-size: 0.8em;
  margin-bottom: 3px;
  color: ${COLOR.MEDIUM_GREY};
`

export const StatsOutput = styled.div`
  font-size: 12px;
`

export const Subtitle = styled.div`
  margin-bottom: 10px;
  font-size: 17px;
`

export const HelpInputText = styled.div`
  font-size: 8px;
  margin-top: 2px;
`

export const TabButtonsContainer = styled.div`
  display: flex;
  gap: 5px;
`

interface TabButtonProps {
  isSelected?: boolean
}

export const TabButton = styled.button<TabButtonProps>`
  padding: 8px 23px;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 300;
  color: ${({ isSelected }: TabButtonProps) => (isSelected ? COLOR.MEDIUM_DARK_BLUE : COLOR.GREY)};
  background-color: ${({ isSelected }: TabButtonProps) =>
    isSelected ? COLOR.SUPER_LIGHT_BLUE : COLOR.SUPER_LIGHT_GREY};
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${({ isSelected }: TabButtonProps) =>
      isSelected ? COLOR.LIGHT_BLUE : COLOR.MEDIUM_SUPER_LIGHT_GREY};
    color: ${({ isSelected }: TabButtonProps) => (isSelected ? COLOR.DARK_BLUE : COLOR.MEDIUM_GREY)};
  }
  border: none;
  border-radius: 10px;
  cursor: pointer;
`
