export const API_GET_KEEPERS_BY_ZIPCODES = "API_GET_KEEPERS_BY_ZIPCODES"
export const API_GET_KEEPER_WALLET_DETAILS = "API_GET_KEEPER_WALLET_DETAILS"
export const RESET_FOCUS_KEEPER = "RESET_FOCUS_KEEPER"
export const RESET_FOCUS_KEEPER_PARCELS = "RESET_FOCUS_KEEPER_PARCELS"

/* KEEPERS */
export const API_GET_KEEPER_STATS = "API_GET_KEEPER_STATS"
export const API_SEARCH_KEEPER = "API_SEARCH_KEEPER"
export const RESET_AVAILABILITIES = "RESET_AVAILABILITIES"
export const API_CLEAR_AGENDA = "API_CLEAR_AGENDA"
export const API_REJECT_KEEPER = "API_REJECT_KEEPER"
export const API_ASK_KEEPER_WALLET_TRANSFER = "API_ASK_KEEPER_WALLET_TRANSFER"
export const API_GET_KEEPER_PARCELS = "API_GET_KEEPER_PARCELS"
export const API_GET_KEEPER_PARCELS_STATS = "API_GET_KEEPER_PARCELS_STATS"
export const API_UPDATE_ALLOWED_CARRIER = "API_UPDATE_ALLOWED_CARRIER"
export const API_REACTIVATE_KEEPER = "API_REACTIVATE_KEEPER"
export const API_ANONYMIZE_KEEPER = "API_ANONYMIZE_KEEPER"
