import { gql } from "@apollo/client"

export interface GetEventPopupsInput {
  input: {
    take: number
    skip: number | null
  }
}

export interface EventPopup {
  id: string
  title: string
  image?: string
  content: string
  ctaText: string
  redirectTo: string
  zipcodes: string[]
  startDate: Date
  endDate: Date
  createdAt: Date
  updatedAt: Date
  onlyForKeeperIds: string[]
}

export interface SaveEventPopupInput {
  saveEventPopupInput: {
    id?: string
    title: string
    image?: string
    uploadImage?: File | Blob
    content: string
    ctaText: string
    redirectTo: string
    zipcodes: string[]
    startDate: Date
    endDate: Date
    onlyKeeperIds: string[]
  }
}

export interface GetEventPopupsResult {
  adminGetEventPopups: {
    hasNextPage: boolean
    eventPopups: EventPopup[]
  }
}

export const adminGetEventPopups = gql`
  query adminGetEventPopups($input: PaginatedEventPopupInput!) {
    adminGetEventPopups(input: $input) {
      hasNextPage
      eventPopups {
        id
        title
        image
        content
        ctaText
        redirectTo
        startDate
        endDate
        createdAt
        updatedAt
        zipcodes
        onlyForKeeperIds
      }
    }
  }
`

export const adminRemoveEventPopupById = gql`
  mutation adminRemoveEventPopupById($id: String!) {
    adminRemoveEventPopupById(id: $id)
  }
`

export const adminSaveEventPopup = gql`
  mutation adminSaveEventPopup($saveEventPopupInput: SaveEventPopupInput!) {
    adminSaveEventPopup(saveEventPopupInput: $saveEventPopupInput)
  }
`
