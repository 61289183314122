import React, { ReactElement } from "react"
import styled from "styled-components"

import KeeperCheckChip from "components/KeeperChecking/KeeperCheckChip"
import { KeeperCheckResult } from "types/keeper.types"

const ChipsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

interface KeeperCheckChipsProps {
  id: string
  keeperCheckResult: KeeperCheckResult
  updateKeeperCheckResult: (id: string, checkResult: KeeperCheckResult) => void
}

function KeeperCheckChips({ id, keeperCheckResult, updateKeeperCheckResult }: KeeperCheckChipsProps): ReactElement {
  const updateCheckResult = (chipClicked: KeeperCheckResult) => {
    // CASE 1 : ADDRESS KO already selected and click on DOCUMENT KO
    if (chipClicked === KeeperCheckResult.ADDRESS_REJECTED && keeperCheckResult === KeeperCheckResult.IDCARD_REJECTED) {
      updateKeeperCheckResult(id, KeeperCheckResult.ADDRESS_AND_IDCARD_REJECTED)
    }

    // CASE 2 : DOCUMENT KO already selected and click on ADDRESS KO
    else if (
      chipClicked === KeeperCheckResult.IDCARD_REJECTED &&
      keeperCheckResult === KeeperCheckResult.ADDRESS_REJECTED
    ) {
      updateKeeperCheckResult(id, KeeperCheckResult.ADDRESS_AND_IDCARD_REJECTED)
    }
    // CASE 3 : DOCUMENT and CNI KO, and click on DOCUMENT KO
    else if (
      keeperCheckResult === KeeperCheckResult.ADDRESS_AND_IDCARD_REJECTED &&
      chipClicked === KeeperCheckResult.IDCARD_REJECTED
    ) {
      updateKeeperCheckResult(id, KeeperCheckResult.ADDRESS_REJECTED)
    }
    // CASE 4 : DOCUMENT and CNI KO, and click on ADDRESS KO
    else if (
      keeperCheckResult === KeeperCheckResult.ADDRESS_AND_IDCARD_REJECTED &&
      chipClicked === KeeperCheckResult.ADDRESS_REJECTED
    ) {
      updateKeeperCheckResult(id, KeeperCheckResult.IDCARD_REJECTED)
    } else {
      if (chipClicked === keeperCheckResult) updateKeeperCheckResult(id, null)
      else updateKeeperCheckResult(id, chipClicked)
    }
  }

  return (
    <ChipsContainer>
      <KeeperCheckChip
        checkResult={KeeperCheckResult.VALIDATED}
        disabled={![null, KeeperCheckResult.VALIDATED].includes(keeperCheckResult)}
        active={keeperCheckResult === KeeperCheckResult.VALIDATED}
        onClick={() => updateCheckResult(KeeperCheckResult.VALIDATED)}
      />

      <KeeperCheckChip
        checkResult={KeeperCheckResult.ADDRESS_REJECTED}
        disabled={
          ![
            null,
            KeeperCheckResult.ADDRESS_REJECTED,
            KeeperCheckResult.IDCARD_REJECTED,
            KeeperCheckResult.ADDRESS_AND_IDCARD_REJECTED,
          ].includes(keeperCheckResult)
        }
        active={[KeeperCheckResult.ADDRESS_REJECTED, KeeperCheckResult.ADDRESS_AND_IDCARD_REJECTED].includes(
          keeperCheckResult,
        )}
        onClick={() => updateCheckResult(KeeperCheckResult.ADDRESS_REJECTED)}
      />

      <KeeperCheckChip
        checkResult={KeeperCheckResult.IDCARD_REJECTED}
        disabled={
          ![
            null,
            KeeperCheckResult.ADDRESS_REJECTED,
            KeeperCheckResult.IDCARD_REJECTED,
            KeeperCheckResult.ADDRESS_AND_IDCARD_REJECTED,
          ].includes(keeperCheckResult)
        }
        active={[KeeperCheckResult.IDCARD_REJECTED, KeeperCheckResult.ADDRESS_AND_IDCARD_REJECTED].includes(
          keeperCheckResult,
        )}
        onClick={() => updateCheckResult(KeeperCheckResult.IDCARD_REJECTED)}
      />
    </ChipsContainer>
  )
}

export default KeeperCheckChips
