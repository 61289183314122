import React from "react"
import { Button, Header, Icon, Modal } from "semantic-ui-react"
import { ModalContent } from "./ConfirmReroutingParcelToBP.styled"

interface ConfirmReroutingParcelToBpProps {
  isOpened: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmReroutingParcelToBp = ({ isOpened, onClose, onSubmit }: ConfirmReroutingParcelToBpProps) => {
  return (
    <Modal basic onClose={onClose} open={isOpened} size="small">
      <Header icon>
        <Icon name="attention" />
        En êtes vous sûr?
      </Header>
      <ModalContent>
        Cette action annulera le colis. Êtes-vous sûr de vouloir demander un reroutage de ce colis vers un Bureau de
        Poste ?
      </ModalContent>
      <Modal.Actions>
        <Button basic color="red" inverted onClick={onClose}>
          <Icon name="remove" /> Annuler
        </Button>
        <Button
          color="green"
          inverted
          onClick={async () => {
            onSubmit()
            onClose()
          }}
        >
          <Icon name="checkmark" /> Valider
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
