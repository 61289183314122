import {
  API_CREATE_PARCEL_FLAG,
  API_CREATE_PARCEL_NOTE,
  API_DELETE_PARCEL_NOTE,
  API_GET_KEEPERS_TO_REASSIGN,
  API_GET_PARCEL_EVENTS,
  API_GET_PARCEL_INFOS,
  API_GET_DATA_FROM_CARRIER_API,
  API_REMOVE_PARCEL_FLAG,
  API_SEARCH_KEEPERS_TO_REASSIGN,
  API_UPDATE_PARCEL_NOTE,
  RESET_KEEPERS_TO_REASSIGN,
  API_GET_AVAILABLE_COLLECTION_CARRIERS,
  API_GET_ALL_PARCEL,
  RESET_FOCUS_PARCEL,
  API_ASK_RETURN_PARCEL,
  API_RETURN_COMPLETED_PARCEL,
  API_GET_RETURNING_PARCELS,
  API_GET_IN_DELIVERY_PARCELS,
  RESET_PARCELS,
  API_SEND_PARCEL_ADMIN_EVENT,
  API_SIMULATE_PARCEL_EVENT,
  API_GET_PARCELS_STATS,
  API_FORCE_REROUTING_TO_BP,
} from "actions/parcels"
import { AdminParcelEventToSimulate, ParcelFlag } from "types/parcel.types"

type FctServiceReturn = {
  type: string
  payload: {
    request: {
      method?: string
      url: string
      data?: any
    }
  }
}

export function apiGetParcels(nbResults: number, offset: string = null, parcelStatus: string = null) {
  return {
    type: API_GET_ALL_PARCEL,
    payload: {
      request: {
        url: `/admin/parcels/paginated?first=${nbResults}${offset ? `&after=${offset}` : ""}${
          parcelStatus ? `&filter=${parcelStatus}` : ""
        }`,
      },
      filter: parcelStatus,
    },
  }
}

export function apiGetParcelsStats() {
  return {
    type: API_GET_PARCELS_STATS,
    payload: {
      request: {
        url: `/admin/parcels/stats`,
      },
    },
  }
}

export function apiGetParcelInfos(parcelId: string): FctServiceReturn {
  return {
    type: API_GET_PARCEL_INFOS,
    payload: {
      request: {
        url: `/admin/parcels/${parcelId}/infos`,
      },
    },
  }
}

export function apiGetParcelEvents(parcelId: string): FctServiceReturn {
  return {
    type: API_GET_PARCEL_EVENTS,
    payload: {
      request: {
        url: `/admin/parcels/${parcelId}/events`,
      },
    },
  }
}

export function apiGetReturningParcels() {
  return {
    type: API_GET_RETURNING_PARCELS,
    payload: {
      request: {
        url: `/admin/parcels/returning`,
      },
    },
  }
}

export function apiGetInDeliveryParcels() {
  return {
    type: API_GET_IN_DELIVERY_PARCELS,
    payload: {
      request: {
        url: `/admin/parcels/inDelivery`,
      },
    },
  }
}

export function apiParcelAskReturn(_data) {
  return {
    type: API_ASK_RETURN_PARCEL,
    payload: {
      request: {
        method: "POST",
        url: `/admin/parcels/askReturn`,
        data: _data,
      },
    },
  }
}

export function apiParcelReturnCompleted(_data) {
  return {
    type: API_RETURN_COMPLETED_PARCEL,
    payload: {
      request: {
        method: "POST",
        url: `/admin/parcels/returnCompleted`,
        data: _data,
      },
    },
  }
}

export function apiSendParcelAdminEvent(_data) {
  return {
    type: API_SEND_PARCEL_ADMIN_EVENT,
    payload: {
      request: {
        method: "POST",
        url: `/admin/parcels/adminEvents`,
        data: _data,
      },
    },
  }
}

export function apiSimulateParcelEvent(_data: AdminParcelEventToSimulate): FctServiceReturn {
  return {
    type: API_SIMULATE_PARCEL_EVENT,
    payload: {
      request: {
        method: "POST",
        url: `/admin/test/simulate-parcel-event`,
        data: _data,
      },
    },
  }
}

/**
 * FLAGS
 */

export function apiCreateParcelFlag(parcelId: string, _data: { flag: ParcelFlag }): FctServiceReturn {
  return {
    type: API_CREATE_PARCEL_FLAG,
    payload: {
      request: {
        method: "POST",
        url: `/admin/parcels/${parcelId}/flag`,
        data: _data,
      },
    },
  }
}
export function apiRemoveParcelFlag(parcelId: string): FctServiceReturn {
  return {
    type: API_REMOVE_PARCEL_FLAG,
    payload: {
      request: {
        method: "DELETE",
        url: `/admin/parcels/${parcelId}/flag`,
      },
    },
  }
}

/**
 * PARCEL NOTES
 */

export function apiCreateParcelNote(_data: { parcelId: string; text: string; zendeskLink: string }): FctServiceReturn {
  return {
    type: API_CREATE_PARCEL_NOTE,
    payload: {
      request: {
        method: "POST",
        url: `/admin/parcels/notes`,
        data: _data,
      },
    },
  }
}
export function apiUpdateParcelNote(noteId: string, _data: { text: string; zendeskLink: string }): FctServiceReturn {
  return {
    type: API_UPDATE_PARCEL_NOTE,
    payload: {
      request: {
        method: "PATCH",
        url: `/admin/parcels/notes/${noteId}`,
        data: _data,
      },
    },
  }
}
export function apiDeleteParcelNote(noteId: string): FctServiceReturn {
  return {
    type: API_DELETE_PARCEL_NOTE,
    payload: {
      request: {
        method: "DELETE",
        url: `/admin/parcels/notes/${noteId}`,
      },
    },
  }
}

export function resetParcels(): { type: string } {
  return {
    type: RESET_PARCELS,
  }
}

export function resetFocusParcel(): { type: string } {
  return {
    type: RESET_FOCUS_PARCEL,
  }
}

export function apiGetKeepersToReassign(parcelId: string, first: number, after: string): FctServiceReturn {
  return {
    type: API_GET_KEEPERS_TO_REASSIGN,
    payload: {
      request: {
        url: `/admin/parcels/${parcelId}/reassign/get?first=${first}&after=${after}`,
      },
    },
  }
}

export function apiSearchKeepersToReassign(searchText: string, parcelId: string): FctServiceReturn {
  return {
    type: API_SEARCH_KEEPERS_TO_REASSIGN,
    payload: {
      request: {
        url: `/admin/parcels/${parcelId}/reassign/search?searchText=${searchText}`,
      },
    },
  }
}

export function resetKeepersToReassign(): { type: string } {
  return {
    type: RESET_KEEPERS_TO_REASSIGN,
  }
}

export function apiGetDataFromCarrierAPI(data: { parcelNo: string; carrierId: string }): FctServiceReturn {
  return {
    type: API_GET_DATA_FROM_CARRIER_API,
    payload: {
      request: {
        method: "POST",
        url: `/admin/parcels/carrierAPI/data`,
        data: data,
      },
    },
  }
}
/**
 * PARCEL COLLECT
 */

/**
 * Get available carriers to collect and return a parcel.
 *
 * @param parcelId - Parcel ID
 *
 * @returns List of carriers available
 */
export function apiGetAvailableCollectionCarriers(parcelId: string): FctServiceReturn {
  return {
    type: API_GET_AVAILABLE_COLLECTION_CARRIERS,
    payload: {
      request: {
        url: `/admin/parcels/${parcelId}/availableCollectionCarriers`,
      },
    },
  }
}

export function apiForceReroutingToBP(parcelId: string): FctServiceReturn {
  return {
    type: API_FORCE_REROUTING_TO_BP,
    payload: {
      request: {
        method: "POST",
        url: `/admin/parcels/forceReroutingToBP`,
        data: {
          parcelId,
        },
      },
    },
  }
}
