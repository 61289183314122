import styled from "styled-components"
import { COLOR } from "utils/color"

export const ButtonContainer = styled.div`
  margin-top: 10px;
`

export const Subtitle = styled.p`
  font-style: italic;
  color: ${COLOR.MEDIUM_LIGHT_GREY};
  font-size: 12px;
  width: 50%;
`
