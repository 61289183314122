import React, { ReactElement, useMemo, useState } from "react"
import { Formik, Field } from "formik"
import Title from "components/Title"
import { useLocation } from "react-router-dom"
import { PageWrapper } from "components/Layout/Structure"
import {
  CustomEventPopupInputComponent,
  redirectToOptions,
} from "components/EventPopups/CustomEventPopupInputComponent"
import { useMutation } from "@apollo/client"
import {
  adminGetEventPopups,
  adminSaveEventPopup,
  EventPopup,
  SaveEventPopupInput,
} from "services/graphql/queries/eventPopup.queries"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import moment from "moment/moment"
import { StyledForm, SubmitButton } from "components/EventPopups/EventPopups.styled"
import { formatKeeperIdsFromStringToArray } from "utils/input"

const IMAGE_MAX_SIZE = 500000

interface FormValues {
  id: null | string
  image: null | File | Blob
  title: string
  content: string
  ctaText: string
  startDate: null | string | Date
  endDate: null | string | Date
  zipcodes: string[]
  redirectTo: "Dashboard" | "Agenda" | "" | string
  onlyKeeperIds: ""
}

const initialValues: FormValues = {
  id: null,
  image: null,
  title: "",
  content: "",
  ctaText: "OK",
  startDate: null,
  endDate: null,
  zipcodes: [],
  redirectTo: "Dashboard",
  onlyKeeperIds: "",
}

export function EventPopupEdit(): ReactElement {
  const { state } = useLocation()
  const history = useHistory()

  const eventPopup = useMemo(() => {
    if (typeof state === "object" && "eventPopup" in state) {
      const { id, image, endDate, startDate, ctaText, redirectTo, content, zipcodes, title, onlyForKeeperIds } =
        state.eventPopup as EventPopup
      return {
        id: id || initialValues.id,
        image: image || initialValues.image,
        startDate: moment(startDate || initialValues.startDate).format("YYYY-MM-DDTHH:mm"),
        endDate: moment(endDate || initialValues.endDate).format("YYYY-MM-DDTHH:mm"),
        ctaText: ctaText || initialValues.ctaText,
        redirectTo: redirectTo || initialValues.redirectTo,
        content: content || initialValues.content,
        zipcodes: zipcodes || initialValues.zipcodes,
        title: title || initialValues.title,
        onlyKeeperIds: Array.isArray(onlyForKeeperIds) ? onlyForKeeperIds.join(", ") : initialValues.onlyKeeperIds,
      }
    }
    return undefined
  }, [state])

  const [redirectToOption, setRedirectToOption] = useState(
    (eventPopup?.redirectTo &&
      redirectToOptions.find(option => {
        return option.value === eventPopup.redirectTo || option.value === ""
      }).value) ??
      redirectToOptions[0].value,
  )

  const [saveEventPopupMutation] = useMutation<{ adminSaveEventPopup: boolean }, SaveEventPopupInput>(
    adminSaveEventPopup,
  )

  const validate = (values: FormValues) => {
    const errors: { [Key in keyof FormValues]?: string } = {}
    if (!values?.title) errors.title = "Title required!"
    else if (values.title.length > 100) errors.title = "Too long! Max 100 characters"
    if (typeof values.image !== "string") {
      if (values.image?.size > IMAGE_MAX_SIZE) errors.image = "Image can not be bigger than 500 kb!"
      if (!values.image?.type?.startsWith("image")) errors.image = "Only images allowed!"
    }
    if (!values.image) errors.image = "Image required!"
    if (!values.content) errors.content = "Content required!"
    if (!values.ctaText) errors.ctaText = "Cta Text required!"
    if (!values.startDate) errors.startDate = "Star tDate required!"
    if (!values.endDate) errors.endDate = "End Date required!"
    if (
      !values.redirectTo ||
      (values.redirectTo !== "Dashboard" && values.redirectTo !== "Agenda" && !values.redirectTo.startsWith("http"))
    )
      errors.redirectTo = "Wrong URL!"
    return errors
  }

  const onSubmit = async (values: typeof initialValues) => {
    let onlyForKeeperIdsFromServer = []

    if (typeof state === "object" && "eventPopup" in state) {
      onlyForKeeperIdsFromServer = state.eventPopup?.onlyForKeeperIds || []
    }

    const onlyKeeperIdsFromInput = formatKeeperIdsFromStringToArray(values.onlyKeeperIds)

    let hasChangedKeeperIds = false

    /**
     * If the data has not been changed, send a 'null' value to BE.
     * Otherwise, send the updated data from the input.
     */
    if (onlyForKeeperIdsFromServer.length !== onlyKeeperIdsFromInput.length) {
      hasChangedKeeperIds = true
    } else if (
      JSON.stringify([...onlyForKeeperIdsFromServer].sort()) !== JSON.stringify([...onlyKeeperIdsFromInput].sort())
    ) {
      hasChangedKeeperIds = true
    }

    try {
      await saveEventPopupMutation({
        variables: {
          saveEventPopupInput: {
            ...values,
            startDate: new Date(values.startDate),
            endDate: new Date(values.endDate),
            image: values.image && typeof values.image === "string" ? values.image : undefined,
            uploadImage: values.image && typeof values.image !== "string" ? values.image : undefined,
            onlyKeeperIds: hasChangedKeeperIds ? onlyKeeperIdsFromInput : null,
          },
        },
        refetchQueries: [{ query: adminGetEventPopups, variables: { input: { take: 15, skip: 0 } } }],
        onCompleted: () => {
          history.push({ pathname: "/event-popups" })
          toast.success("Event Popup successfully saved!")
        },
      })
    } catch (e) {
      toast.error("Fail to save event: " + e.message)
    }
  }

  return (
    <PageWrapper>
      <Formik initialValues={eventPopup || initialValues} validate={validate} onSubmit={onSubmit}>
        <StyledForm>
          <Title>{`📣 ${eventPopup?.id ? "Modifier" : "Ajouter"} une Event Popup`}</Title>
          <Field type="image" name="image" component={CustomEventPopupInputComponent} />
          <Field type="text" name="title" label="Titre de la popup" component={CustomEventPopupInputComponent} />
          <Field
            type="textarea"
            name="content"
            label="Contenu de la popup"
            component={CustomEventPopupInputComponent}
          />
          <Field type="text" name="ctaText" label="Texte du CTA" component={CustomEventPopupInputComponent} />
          <Field
            type="zipcodes"
            name="zipcodes"
            label="Filtrer par code postal ? (Actif pour tous les Keepers si aucun code postal renseigné)"
            component={CustomEventPopupInputComponent}
          />
          <Field
            type="onlyKeeperIds"
            name="onlyKeeperIds"
            label="Filtrer par keeperID"
            component={CustomEventPopupInputComponent}
          />
          <Field
            type="datetime-local"
            name="startDate"
            label="Date et heure de début"
            component={CustomEventPopupInputComponent}
          />
          <Field
            type="datetime-local"
            name="endDate"
            label="Date et heure de fin"
            component={CustomEventPopupInputComponent}
          />
          <Field
            type="select"
            name="redirectTo"
            setRedirectToOption={setRedirectToOption}
            label="Redirection vers"
            component={CustomEventPopupInputComponent}
          />
          {redirectToOption === "" && (
            <Field type="text" name="redirectTo" placeholder="URL" component={CustomEventPopupInputComponent} />
          )}
          <SubmitButton content="Valider" type={"submit"} />
        </StyledForm>
      </Formik>
    </PageWrapper>
  )
}

export default EventPopupEdit
