import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Checkbox, Modal } from "semantic-ui-react"
import { CarriersName } from "types/carrier.types"
import {
  CheckboxWrapper,
  CheckIcon,
  CollectionCarrierDetails,
  CollectionCarrierDetailsHelpUrl,
  ReturnRequestModalActions,
  ReturnRequestModalButton,
  ReturnRequestModalButtonActive,
} from "./RequestReturnModal.styled"
import { useMutation } from "@apollo/client"
import { Company } from "types/company.types"
import { DateFields } from "./DateFields"
import { ParcelFields } from "./ParcelFields"
import { DestinationFields } from "./DestinationFields"
import { DefaultFields } from "./DefaultFields"
import { checkIsSubmitEnabled, getDisplayBlocks } from "./helpers"
import {
  saveBtoCRRFPacman,
  SaveBtoCRRFPacmanInput,
  SaveBtoCRRFPacmanVariables,
} from "services/graphql/mutations/return-request"
import { AdminParcelEventReason } from "types/parcel.types"
import { preparePrefilledDataForRRF, reasonOptions } from "./data"
import { BtocReturnRequestForm, ReturnRequestFieldKeys, ReturnRequestFormData } from "types/return-request.types"
import { apiGetParcelEvents, apiGetParcelInfos } from "services/parcels"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { getErrorMessage, ParcelCollectionErrorCodes } from "utils/custom-errors"
import moment from "moment"

interface RequestReturnModalProps {
  isOpened: boolean
  onClose: () => void
  collectionCarriers: Company[]
  btocReturnRequestForm: BtocReturnRequestForm
  nbReturnsRequested: number
  deliveryCarrier: CarriersName
}

export const ReturnRequestModal = ({
  isOpened,
  onClose,
  collectionCarriers,
  btocReturnRequestForm,
  nbReturnsRequested,
}: RequestReturnModalProps) => {
  const history = useParams()
  const dispatch = useDispatch()

  const { id: parcelId } = history

  const [saveBtoCRRFPacmanMutation] = useMutation<boolean, SaveBtoCRRFPacmanInput>(saveBtoCRRFPacman)

  const [returnRequestFormData, setReturnRequestFormData] = useState<ReturnRequestFormData>({
    isAlreadyCollected: false,
    isAlreadyOrderedToReturningCarrier: false,
    returningCarrier: null,
    pickupDate: "",
    timeIntervalStart: null,
    timeIntervalEnd: null,
    cotransportationCode: "",
    referenceCode: "",
    parcelDimensions: null,
    returnReason: null,
    returnReasonDetails: "",
    deliveryAddressId: "",
    deliveryAddressName: "",
    deliveryAddress: "",
    deliveryPhone: "",
    deliveryInstructions: "",
    deliveryAddressZipcode: "",
    deliveryAddressCity: "",
    deliveryAddressLatitude: "",
    deliveryAddressLongitude: "",
  })

  /** If btocReturnRequestForm id is null, it means that agent creates return request from scratch and there is no entity id DB */
  const isBtocRRFCreatedPreviously = !!btocReturnRequestForm?.id
  const selectedReturningCarrierName = returnRequestFormData?.returningCarrier?.data?.name
  const {
    minimumSlotDuration = 0,
    minimumHour = "",
    maximumHour = "",
    helpUrl = "",
  } = btocReturnRequestForm?.returningCarrier?.collectionOptions ||
  returnRequestFormData?.returningCarrier?.data?.collectionOptions ||
  {}

  /** Prefill the form with data from BE */
  useEffect(() => {
    if (!btocReturnRequestForm) return

    const prefilledData = preparePrefilledDataForRRF({
      btocReturnRequestForm,
      collectionCarriers,
    })

    if (prefilledData) {
      setReturnRequestFormData(prevState => ({ ...prevState, ...prefilledData }))
    }
  }, [btocReturnRequestForm, collectionCarriers])

  const submitRRF = async () => {
    if (
      !parcelId ||
      !returnRequestFormData?.returningCarrier?.value ||
      !returnRequestFormData?.returnReason?.value ||
      (returnRequestFormData?.returnReason?.value === AdminParcelEventReason.OTHER &&
        !returnRequestFormData.returnReasonDetails)
    ) {
      toast.error(getErrorMessage(ParcelCollectionErrorCodes.DETAILS_MISSING))
      return
    }

    const payload = {
      id: btocReturnRequestForm?.id,
      alreadyCollected: returnRequestFormData.isAlreadyCollected,
      alreadyOrderedToReturningCarrier: returnRequestFormData.isAlreadyOrderedToReturningCarrier,
      parcelId: parcelId,
      reason: returnRequestFormData?.returnReason?.value,
      returningCarrierId: returnRequestFormData.returningCarrier.value,
      referenceCode: returnRequestFormData.referenceCode,
      cotransportationCode: returnRequestFormData.cotransportationCode,
      rrfAddress: {
        deliveryAddressId: returnRequestFormData.deliveryAddressId,
        deliveryAddressName: returnRequestFormData.deliveryAddressName,
        deliveryAddress: returnRequestFormData.deliveryAddress,
        deliveryPhone: returnRequestFormData.deliveryPhone,
        deliveryInstructions: returnRequestFormData.deliveryInstructions,
        deliveryAddressCity: returnRequestFormData.deliveryAddressCity,
        deliveryAddressZipcode: returnRequestFormData.deliveryAddressZipcode,
        latitude: returnRequestFormData.deliveryAddressLatitude,
        longitude: returnRequestFormData.deliveryAddressLongitude,
      },
    } as SaveBtoCRRFPacmanVariables

    if (returnRequestFormData?.returnReason?.value === AdminParcelEventReason.OTHER) {
      payload.details = returnRequestFormData.returnReasonDetails
    }

    if (returnRequestFormData.isAlreadyCollected) {
      payload.reason = AdminParcelEventReason.ALREADY_COLLECTED_BY_CARRIER
    }

    if (
      !returnRequestFormData.isAlreadyCollected &&
      [CarriersName.GLS, CarriersName.DHL, CarriersName.TUT_TUT].includes(selectedReturningCarrierName)
    ) {
      payload.pickupDate = new Date(returnRequestFormData.pickupDate) || null
      payload.interval = {
        start: moment(
          `${returnRequestFormData.pickupDate} ${returnRequestFormData.timeIntervalStart.value}`,
          "YYYY-MM-DD HH:mm",
        ).toISOString(),
        end: moment(
          `${returnRequestFormData.pickupDate} ${returnRequestFormData.timeIntervalEnd.value}`,
          "YYYY-MM-DD HH:mm",
        ).toISOString(),
      }
    }

    if (!returnRequestFormData.isAlreadyCollected && selectedReturningCarrierName === CarriersName.TUT_TUT) {
      payload.dimensions = returnRequestFormData.parcelDimensions.value
    }

    try {
      saveBtoCRRFPacmanMutation({ variables: { payload } })
        .then(() => {
          toast.success("Demande de retour validée")
          onClose()

          dispatch(apiGetParcelInfos(parcelId))
          dispatch(apiGetParcelEvents(parcelId))
        })
        .catch(e => {
          toast.error(getErrorMessage(e?.message))
        })
    } catch (e) {
      toast.error(getErrorMessage(e?.message))
      console.error(e)
    }
  }

  const changeData = (key: ReturnRequestFieldKeys, value) => {
    if (key === ReturnRequestFieldKeys.isAlreadyOrderedToReturningCarrier && value) {
      return setReturnRequestFormData(prevState => ({
        ...prevState,
        [key]: value,
        isAlreadyCollected: false,
        returnReason: prevState.isAlreadyCollected ? null : prevState.returnReason,
      }))
    }

    if (key !== ReturnRequestFieldKeys.isAlreadyCollected) {
      return setReturnRequestFormData(prevState => ({ ...prevState, [key]: value }))
    }

    /** If the agent sets isAlreadyCollected to true, the return reason will be set automatically, and the field will be disabled */
    if (value) {
      setReturnRequestFormData(prevState => ({
        ...prevState,
        [key]: value,
        isAlreadyOrderedToReturningCarrier: false,
        returnReason: reasonOptions.find(
          reasonOption => reasonOption.value === AdminParcelEventReason.ALREADY_COLLECTED_BY_CARRIER,
        ),
      }))
    } else {
      setReturnRequestFormData(prevState => ({ ...prevState, [key]: value, returnReason: null }))
    }
  }

  const isSubmitEnabled = checkIsSubmitEnabled(returnRequestFormData, minimumSlotDuration)

  const { isHelpSection, isDateFields, isParcelFields, isDestinationFields } = getDisplayBlocks(
    selectedReturningCarrierName,
    returnRequestFormData.isAlreadyCollected,
  )

  return (
    <Modal open={isOpened}>
      <Modal.Header>Demande de retour (déjà {nbReturnsRequested} demande(s) effectuée(s)) </Modal.Header>
      <Modal.Content>
        {/** Checkbox - is already collected */}
        <CheckboxWrapper>
          <Checkbox
            label="Le colis a déjà été collecté"
            onClick={() =>
              changeData(ReturnRequestFieldKeys.isAlreadyCollected, !returnRequestFormData.isAlreadyCollected)
            }
            checked={returnRequestFormData.isAlreadyCollected}
          />
        </CheckboxWrapper>

        {/** Checkbox - is already ordered to returning carrier */}
        <CheckboxWrapper>
          <Checkbox
            label="La demande a déjà été faite"
            onClick={() =>
              changeData(
                ReturnRequestFieldKeys.isAlreadyOrderedToReturningCarrier,
                !returnRequestFormData.isAlreadyOrderedToReturningCarrier,
              )
            }
            checked={returnRequestFormData.isAlreadyOrderedToReturningCarrier}
          />
        </CheckboxWrapper>

        <DefaultFields
          returnRequestFormData={returnRequestFormData}
          collectionCarriers={collectionCarriers}
          changeData={changeData}
          isBtocRRFCreatedPreviously={isBtocRRFCreatedPreviously}
        />

        {isHelpSection && (
          <CollectionCarrierDetails>
            <CollectionCarrierDetailsHelpUrl>
              📚 {"  "}
              <a href={helpUrl} target="_blank" rel="noreferrer">
                Comment faire un retour de colis avec {selectedReturningCarrierName} ?
              </a>
            </CollectionCarrierDetailsHelpUrl>
          </CollectionCarrierDetails>
        )}

        {isDateFields && (
          <DateFields
            returnRequestFormData={returnRequestFormData}
            changeData={changeData}
            collectData={{
              maximumHour,
              minimumHour,
              minimumSlotDuration,
            }}
          />
        )}

        {isParcelFields && <ParcelFields returnRequestFormData={returnRequestFormData} changeData={changeData} />}

        {isDestinationFields && (
          <DestinationFields returnRequestFormData={returnRequestFormData} changeData={changeData} />
        )}
      </Modal.Content>
      <ReturnRequestModalActions>
        <ReturnRequestModalButton color="black" onClick={onClose}>
          Annuler
        </ReturnRequestModalButton>
        <ReturnRequestModalButtonActive disabled={!isSubmitEnabled} onClick={submitRRF} positive>
          Valider <CheckIcon name={"checkmark"} />
        </ReturnRequestModalButtonActive>
      </ReturnRequestModalActions>
    </Modal>
  )
}
