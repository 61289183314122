import styled from "styled-components"
import { COLOR } from "utils/color"

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StatusText = styled.div<{ status: "success" | "error" }>`
  font-size: 11px;
  line-height: 14px;
  text-align: left;
  color: ${({ status }) => (status === "success" ? COLOR.GREEN : COLOR.RED)};
`

export const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`

export const PopupContent = styled.div`
  font-size: 10px;
  max-width: 300px;
`

export const ValidationItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`

export const ValidationStatus = styled.span<{ isValid: boolean }>`
  color: ${({ isValid }) => (isValid ? COLOR.GREEN : COLOR.RED)};
`

export const AIDecisionText = styled.div`
  font-size: 11px;
  line-height: 14px;
  text-align: left;
  display: flex;
  flex-direction: column;
`
