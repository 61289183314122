import React, { ReactElement, useEffect, useState } from "react"
import LineElementRow from "components/Table/LineElementRow"
import LineElementCol from "components/Table/LineElementCol"
import { ButtonsContainer, ButtonStyled, ItalicText, LoadingComponentStyled } from "./RequestedTransfers.styled"
import {
  BlockBackground,
  ButtonContainer,
  Container,
  DetailInfo,
  InProgressText,
  LineElementContainerStyled,
  SeeDetailButton,
  TransfersNumber,
  Wrapper,
} from "../WireTransfers.styled"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "types/reduxState.types"
import { apiGetAllPayments, apiGetNewPayments, apiMoveNewPaymentsToDone, resetPayments } from "services/payments"
import { DetailLineHeader } from "../DetailLine/DetailLineHeader"
import { LoadingComponent } from "components/LoadingComponent"
import { WIRE_TRANSFERS_LIMIT } from "../data/constants"
import { DetailLine } from "../DetailLine/DetailLine"
import { PaymentExtended } from "types/payments.types"
import { COLOR } from "utils/color"
import { toast } from "react-toastify"

export const RequestedTransfers = (): ReactElement => {
  const dispatch = useDispatch()
  const paymentsNew = useSelector((state: ReduxState) => state.payments.listNew)

  const [isDetailOpened, setIsDetailOpened] = useState(false)
  const [xmlFileLink, setXmlFileLink] = useState("")
  const [isLoadingDownload, setIsLoadingDownload] = useState(false)
  const [isLoadingUpdatedData, setIsLoadingUpdatedData] = useState(false)

  useEffect(() => {
    dispatch(apiGetNewPayments({ limit: WIRE_TRANSFERS_LIMIT }))
  }, [])

  const paymentsNewData = paymentsNew?.data
  const loadingNewPayments = paymentsNew.loading

  const handleMakeDoneAndExportXML = async () => {
    if (!paymentsNewData?.length || !Array.isArray(paymentsNewData)) return

    const paymentsNewIds = paymentsNewData.map(payment => payment.id)

    const res = await dispatch(apiMoveNewPaymentsToDone(paymentsNewIds))

    // @ts-ignore
    if (res.payload.request.status !== 200) return

    try {
      setIsLoadingUpdatedData(true)
      // @ts-ignore
      const xmlFileLink = res.payload.data.xmlFileLink

      setXmlFileLink(xmlFileLink || "")

      dispatch(resetPayments())

      setTimeout(() => {
        setIsLoadingUpdatedData(false)
        dispatch(apiGetAllPayments({ limit: WIRE_TRANSFERS_LIMIT }))
        dispatch(apiGetNewPayments({ limit: WIRE_TRANSFERS_LIMIT }))
      }, 1500)
    } catch (e) {
      setIsLoadingUpdatedData(false)
      console.error(e)
    }
  }

  const downloadXmlFile = async () => {
    try {
      setIsLoadingDownload(true)
      window.open(xmlFileLink, "_blank")
    } catch (e) {
      toast.error(e.message)
    } finally {
      setIsLoadingDownload(false)
    }
  }

  return (
    <>
      <Container>
        <Wrapper>
          <LineElementContainerStyled onClick={() => setIsDetailOpened(!isDetailOpened)} lineHeight="lg">
            <LineElementRow>
              <LineElementCol
                width={"10%"}
                size={2}
                content={
                  <div>
                    <InProgressText>Encours</InProgressText>
                  </div>
                }
              />
              <LineElementCol
                size={8}
                width={"30%"}
                content={
                  <TransfersNumber>
                    <ItalicText>{paymentsNewData?.length || 0} virements demandés ⏳</ItalicText>
                  </TransfersNumber>
                }
              />
              <LineElementCol size={5} content={<></>} />
              <LineElementCol
                size={2}
                content={
                  <ButtonContainer>
                    <SeeDetailButton>Voir le détail</SeeDetailButton>
                  </ButtonContainer>
                }
              />
            </LineElementRow>
          </LineElementContainerStyled>
          <BlockBackground isOpened={isDetailOpened} />
        </Wrapper>
        <DetailInfo isOpened={isDetailOpened}>
          <DetailLineHeader />
          {paymentsNewData?.length ? (
            paymentsNewData?.map((payment: PaymentExtended) => (
              <DetailLine key={payment.id} paymentFromBatch={payment} />
            ))
          ) : (
            <></>
          )}
        </DetailInfo>

        {loadingNewPayments || isLoadingDownload ? (
          <LoadingComponent />
        ) : (
          <ButtonsContainer>
            {paymentsNewData?.length ? (
              <ButtonStyled onClick={handleMakeDoneAndExportXML}>Exporter et marquer comme payé</ButtonStyled>
            ) : (
              <></>
            )}
            {xmlFileLink && (
              <ButtonStyled isDownload={COLOR.LIGHT_GREY} onClick={downloadXmlFile}>
                Télécharger le fichier XML
              </ButtonStyled>
            )}
          </ButtonsContainer>
        )}
      </Container>

      {isLoadingUpdatedData && <LoadingComponentStyled size={"massive"} />}
    </>
  )
}
