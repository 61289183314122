import { Address } from "types/address.types"
import { AddressAvailability } from "types/agenda.types"
import { KeeperCarrier } from "types/carrier.types"
import { EventEntity } from "types/event.types"
import { Wallet, WalletPacman } from "types/wallet.types"
import { DocumentEntity } from "types/document.types"

export enum KeeperStatus {
  NEW = "NEW",
  PENDING = "PENDING",
  IN_VALIDATION = "IN_VALIDATION",
  VALIDATED = "VALIDATED",
  REJECTED = "REJECTED",
}

export interface Keeper {
  id: string
  email: string
  phone: string
  firstName: string
  lastName: string
  nbSponsored?: number
  birthday: string
  rating: KeeperRating
  didKeeperDeliverHisFirstOrder?: boolean
  nbTotalDeliveredParcels: number
  age: number
  status: KeeperStatus
  pictureUrl?: string
  walletId: string
  keeperOnboardingDone: boolean
  keeperGuideDone: boolean
  keeperChartAccepted: boolean
  passwordResetRequired: boolean
  wantToBeAmbassador: boolean
  documents?: DocumentEntity[]
  __has_sponsored__: boolean
  __sponsor__?: Keeper
  __sponsored__: Keeper[]
  address: Address
  // documents: Document[]
  wallet: Wallet
  createdAt: string
  // stats: KeeperStats
  // returningParcels: {
  //   nb: number
  //   parcels: {
  //     parcelNo: string
  //     pickupDate: string
  //     shipmentId: string
  //     carrierName: string
  //   }[]
  // }
  nbOngoingParcels?: number
  nbTotalParcels?: number

  // Extracts data
  ratings_nb?: string
  ratings_avg?: string
  orders_nb?: string
  currentOpenAiValidationLog?: OpenAiValidationLog
  anonymized?: boolean
  anonymizationDate?: string
}

export type KeeperRating = {
  nb: number
  average?: number
}

export interface DayAgenda {
  date: string
  timeRanges: TimeRange[]
}

export interface TimeRange {
  start: string
  end: string
}

export interface KeeperAvailabilitiesNextDays {
  address: {
    id: Address["id"]
    formattedLine: Address["formattedLine"]
    distance: Address["distance"]
    maxParcels: Address["maxParcels"]
    streetLine: string
    city: string
    zipcode: string
    instructions: string
    building: string
    digicode: string
    floor: string
    keeper: {
      id: Keeper["id"]
      firstName: Keeper["firstName"]
      lastName: Keeper["lastName"]
      phone: string
      nbOngoingParcels: number
      nbTotalParcels: number
      nbTotalDeliveredParcels: number
      keeperScoring?: KeeperScoring
    }
    agenda: DayAgenda[]
  }
}

export interface KeeperPickupPointsProps {
  address: Address
  keeper: Keeper
  distance: number
}

export interface KeeperAdminStats {
  rating: KeeperRating
  nbOngoingParcels?: number
  nbTotalParcels?: number
  nbTotalDeliveredParcels?: number
  nbCurrentSleepingParcels?: number
  nbCurrentUnscannedParcels?: number
}

export interface KeeperScoring {
  week: string
  rating?: number
  deliveryFailureRate: {
    totalRate?: number
    colissimo?: number
    geodis?: number
    gls?: number
    relaisColis?: number
    dhl?: number
  }
  deliveryTime?: number
  contactTime?: number
  commitment7Days?: number
  commitment30Days?: number
}

export interface KeeperNote {
  id: string
  content: string
  zendeskLink: string
}

export interface KeeperQuery {
  keeper: Keeper
  wallet: WalletPacman
  availabilities: AddressAvailability[]
  carriers: KeeperCarrier[]
  events: EventEntity[]
  keeperScoring: KeeperScoring
  documents: DocumentEntity[]
  note: KeeperNote
  listAddresses: Address[]
}

export interface GetKeeperQuery {
  adminGetKeeper: KeeperQuery
}

export interface KeeperWithValidationReason extends Keeper {
  validationReason: KeeperValidationReason
}

export interface GetKeeperToCheckQuery {
  adminGetKeepersToCheck: KeeperWithValidationReason[]
}

export enum KeeperValidationReason {
  NEW_KEEPER = "NEW_KEEPER",
  RE_VALIDATION = "RE_VALIDATION",
  UPDATE_INFORMATIONS = "UPDATE_INFORMATIONS",
  RELOCATION = "RELOCATION",
}

export interface KeeperInChecking extends KeeperWithValidationReason {
  checkResult: KeeperCheckResult
}

export type KeeperChecking = {
  id: string
  checkResult: KeeperCheckResult
}

export enum KeeperCheckResult {
  VALIDATED = "VALIDATED",
  ADDRESS_REJECTED = "ADDRESS_REJECTED",
  IDCARD_REJECTED = "IDCARD_REJECTED",
  ADDRESS_AND_IDCARD_REJECTED = "ADDRESS_AND_IDCARD_REJECTED",
}

export enum KeeperTabs {
  PARCELS,
  AGENDA,
  WALLET,
  EVENTS,
  SPONSOR,
  DOCUMENTS,
  PREVIOUS_PARCELS,
}

export enum OpenAiValidationStatus {
  VALIDATED = "VALIDATED",
  REJECTED = "REJECTED",
}

export interface OpenAiValidationLog {
  status: OpenAiValidationStatus
  idcardStatus: OpenAiValidationStatus
  addressStatus: OpenAiValidationStatus
  explanationToUser: OpenAiValidationStatus
  explanationToAgent: {
    ageOK: OpenAiValidationStatus
    fullNameID: OpenAiValidationStatus
    documentType: OpenAiValidationStatus
    expirationOK: OpenAiValidationStatus
    addressNumber: OpenAiValidationStatus
    birthdateMatch: OpenAiValidationStatus
    documentNumber: OpenAiValidationStatus
    frontPhotoSide: OpenAiValidationStatus
    photoVisibility: OpenAiValidationStatus
    documentAvailable: OpenAiValidationStatus
    nameSurnameMatch: OpenAiValidationStatus
    addressInstruction: OpenAiValidationStatus
  }
}
