import React, { useEffect, useState } from "react"
import {
  Container,
  DetailInfo,
  InProgressText,
  LineElementContainerStyled,
  Section,
  HeadSection,
  SeeDetailButton,
  SemiBoldText,
  TransfersNumber,
  Wrapper,
  Title,
} from "../WireTransfers.styled"
import { useDispatch, useSelector } from "react-redux"
import { ReduxState } from "types/reduxState.types"
import { ButtonContainer, Subtitle } from "./BankAccountProblems.styled"
import LineElementRow from "../../Table/LineElementRow"
import LineElementCol from "../../Table/LineElementCol"
import { DetailLineHeader } from "../DetailLine/DetailLineHeader"
import { PaymentExtended } from "types/payments.types"
import { DetailLine } from "../DetailLine/DetailLine"
import { TotalSum } from "../PerformedTransfersHistory/PerformedTransfersHistory.styled"
import { apiGetRejectedPayments } from "services/payments"
import { WIRE_TRANSFERS_LIMIT } from "../data/constants"
import { LoadingComponent } from "../../LoadingComponent"
import { Button } from "semantic-ui-react"

export const BankAccountProblems = () => {
  const dispatch = useDispatch()

  const [isDetailOpened, setIsDetailOpened] = useState(false)

  useEffect(() => {
    dispatch(apiGetRejectedPayments({ limit: WIRE_TRANSFERS_LIMIT }))
  }, [])

  const paymentsRejected = useSelector((state: ReduxState) => state.payments.listRejected)

  const paymentsRejectedData = paymentsRejected?.data

  const paymentsRejectedList = paymentsRejectedData.payments
  const totalSum = paymentsRejectedData.sum / 100
  const totalCount = paymentsRejectedData.totalCount

  const loadingRejectedPayments = paymentsRejected?.loading

  const moreToLoad = totalCount > paymentsRejectedList.length

  const loadMoreRejectedPayments = () => {
    if (!paymentsRejectedList.length) {
      return
    }

    const cursor = paymentsRejectedList.at(-1).cursor
    dispatch(apiGetRejectedPayments({ limit: WIRE_TRANSFERS_LIMIT, cursor }))
  }

  return (
    <Section>
      <HeadSection>
        <Title>🚩 Problèmes de compte bancaire </Title>
        <Subtitle>
          Ces virements sont en attente d’une mise à jour du compte bancaire par le Keeper. Ils seront automatiquement
          reversés dans les virements demandés une fois la MAJ effectuée.
        </Subtitle>
      </HeadSection>
      <Container>
        <Wrapper>
          <LineElementContainerStyled onClick={() => setIsDetailOpened(!isDetailOpened)} lineHeight="lg">
            <LineElementRow>
              <LineElementCol
                width={"10%"}
                size={2}
                content={
                  <div>
                    <InProgressText>Encours</InProgressText>
                  </div>
                }
              />
              <LineElementCol
                size={8}
                width={"20%"}
                content={<TransfersNumber>{totalCount} virements en attente ⌛</TransfersNumber>}
              />
              <LineElementCol
                size={2}
                content={
                  <TotalSum>
                    Total : <SemiBoldText>{totalSum}€</SemiBoldText>
                  </TotalSum>
                }
              />
              <LineElementCol size={2} content={<></>} />
              <LineElementCol
                size={2}
                content={
                  <ButtonContainer>
                    <SeeDetailButton>Voir le détail</SeeDetailButton>
                  </ButtonContainer>
                }
              />
            </LineElementRow>
          </LineElementContainerStyled>
        </Wrapper>

        <DetailInfo isOpened={isDetailOpened}>
          <DetailLineHeader />
          {paymentsRejectedList?.length ? (
            paymentsRejectedList?.map((payment: PaymentExtended) => (
              <DetailLine key={payment.id} paymentFromBatch={payment} />
            ))
          ) : (
            <></>
          )}

          {!loadingRejectedPayments && moreToLoad && (
            <ButtonContainer>
              <Button onClick={loadMoreRejectedPayments}>Charger plus</Button>
            </ButtonContainer>
          )}
        </DetailInfo>

        {loadingRejectedPayments && <LoadingComponent />}
      </Container>
    </Section>
  )
}
