import React, { ReactElement, useState } from "react"
import { Col, Row } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  apiGetNewPayments,
  apiGetRejectedPayments,
  apiRejectAndRequireBAUpdate,
  apiRevertPayment,
  updatePayments,
} from "services/payments"
import { PaymentExtended } from "types/payments.types"
import { WIRE_TRANSFERS_LIMIT } from "../data/constants"
import { DetailLineContainer, DetailText, RevertButton } from "./DetailLine.styled"
import { ConfirmModal } from "../ConfirmModal/ConfirmModal"
import { ReduxState } from "../../../types/reduxState.types"

interface DetailLineProps {
  paymentFromBatch: PaymentExtended
  canRevertPayment?: boolean
  showUpdateBankInfoBtn?: boolean
  packmanBatchId?: string
  totalCount?: number
}

export const DetailLine = ({
  paymentFromBatch,
  canRevertPayment = false,
  showUpdateBankInfoBtn = false,
}: DetailLineProps): ReactElement => {
  const dispatch = useDispatch()
  const paymentsRejected = useSelector((state: ReduxState) => state.payments.listRejected)

  const [isDisabled, setIsDisabled] = useState(false)
  const [isOpenedRevertConfirmModal, setIsOpenedRevertConfirmModal] = useState(false)
  const [isOpenedUpdateBankingInfoConfirmModal, setIsOpenedUpdateBankingInfoConfirmModal] = useState(false)

  const handleOpenConfirmRevertPayment = () => setIsOpenedRevertConfirmModal(true)

  const handleCloseConfirmRevertPayment = () => setIsOpenedRevertConfirmModal(false)

  const handleOpenConfirmBAUpdate = () => setIsOpenedUpdateBankingInfoConfirmModal(true)

  const handleCloseConfirmBAUpdate = () => setIsOpenedUpdateBankingInfoConfirmModal(false)

  const handleRevertPayment = async () => {
    try {
      setIsDisabled(true)
      const res = await dispatch(
        apiRevertPayment({ paymentId: paymentFromBatch.id, packmanBatchId: paymentFromBatch?.packmanBatchId }),
      )

      // @ts-ignore
      if (res?.payload?.request?.status !== 200) return

      dispatch(updatePayments({ paymentId: paymentFromBatch.id, packmanBatchId: paymentFromBatch?.packmanBatchId }))
      dispatch(apiGetNewPayments({ limit: WIRE_TRANSFERS_LIMIT }))
    } catch (e) {
      setIsDisabled(false)
      console.error(e)
    } finally {
      setIsDisabled(false)
    }
  }

  const paymentsRejectedCount = paymentsRejected?.data.payments.length

  const handleRejectAndRequireBAUpdate = async () => {
    try {
      setIsDisabled(true)
      const res = await dispatch(apiRejectAndRequireBAUpdate(paymentFromBatch.id))

      // @ts-ignore
      if (res?.payload?.request?.status !== 200) return

      dispatch(updatePayments({ paymentId: paymentFromBatch.id, packmanBatchId: paymentFromBatch?.packmanBatchId }))
      dispatch(apiGetRejectedPayments({ limit: paymentsRejectedCount + 1 }))
    } catch (e) {
      setIsDisabled(false)
      console.error(e)
    } finally {
      setIsDisabled(false)
    }
  }

  const formattedPaymentSum = paymentFromBatch.amount / 100

  return (
    <DetailLineContainer>
      <Row>
        <Col xs={3} sm={3} md={2}>
          <DetailText>
            {paymentFromBatch?.firstName} {paymentFromBatch?.lastName}
          </DetailText>
        </Col>
        <Col xs={3} sm={2} lg={1}>
          <DetailText>{formattedPaymentSum}€</DetailText>
        </Col>
        <Col xs={4} sm={3} lg={3}>
          <DetailText>{paymentFromBatch?.iban}</DetailText>
        </Col>
        <Col xs={3} sm={2} lg={2}>
          <DetailText>{paymentFromBatch?.bic}</DetailText>
        </Col>
        {canRevertPayment && (
          <Col sm={2} lg={2}>
            <RevertButton disabled={isDisabled} onClick={handleOpenConfirmRevertPayment}>
              Repasser en “Virement à effectuer”
            </RevertButton>
          </Col>
        )}
        {showUpdateBankInfoBtn && (
          <Col sm={2} lg={2}>
            <RevertButton disabled={isDisabled} onClick={handleOpenConfirmBAUpdate}>
              Demander MAJ RIB au Keeper
            </RevertButton>
          </Col>
        )}
      </Row>
      {isOpenedRevertConfirmModal && (
        <ConfirmModal
          isOpened={isOpenedRevertConfirmModal}
          onClose={handleCloseConfirmRevertPayment}
          onSubmit={handleRevertPayment}
          subtitle={`Vous allez annuler le virement de ${formattedPaymentSum}€ pour ${`${paymentFromBatch?.firstName} ${paymentFromBatch?.lastName}`}`}
        />
      )}
      <ConfirmModal
        title={"En êtes vous sûr ?"}
        isOpened={isOpenedUpdateBankingInfoConfirmModal}
        onClose={handleCloseConfirmBAUpdate}
        onSubmit={handleRejectAndRequireBAUpdate}
        subtitle={`Cette action enverra une notification au Keeper pour mettre à jour ses informations bancaires.`}
      />
    </DetailLineContainer>
  )
}
