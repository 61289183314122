export const formatKeeperIdsFromStringToArray = (onlyKeeperIds: string) => {
  if (!onlyKeeperIds) return []

  const normalizedText = onlyKeeperIds.replace(/\s+/g, ",").trim()

  // Split by commas and spaces
  return normalizedText
    .split(/,/) // Splits by commas, allowing optional spaces
    .map(id => id.trim()) // Trim whitespace
    .filter(id => id.length > 0) // Remove empty values
}
