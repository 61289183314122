import styled from "styled-components"
import { COLOR } from "utils/color"
import LineElementContainer from "components/Table/LineElementContainer"
import { convertHexToRGBA } from "utils/hexToRGBA"

export const SectionsContainer = styled.div`
  padding-bottom: 100px;
`

interface TransferProps {
  isOpened: boolean
}

export const Container = styled.div`
  position: relative;
`

export const TransfersNumber = styled.p`
  font-size: 12px;
  font-weight: 700;
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  padding-right: 15px;
  margin-top: 10px;
`

export const SeeDetailButton = styled.button`
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  border-radius: 10px;
  border: 0;
  padding: 4px 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${COLOR.MEDIUM_SUPER_LIGHT_GREY};
  }
`

export const LineElementContainerStyled = styled(LineElementContainer)`
  position: relative;
  z-index: 50;

  &:hover {
    background-color: ${COLOR.WHITE};
  }
`

export const BlockBackground = styled.div<TransferProps>`
  position: absolute;
  background-color: ${({ isOpened }: TransferProps) => (isOpened ? COLOR.SUPER_LIGHT_GREY : "transparent")};
  right: 0;
  left: 0;
  bottom: 0;
  height: 25px;
  z-index: 10;
  transition: background-color 0.2s ease-in-out;
`

export const DetailInfo = styled.div<TransferProps>`
  position: absolute;
  left: 0;
  right: 0;
  top: 80px;
  transform: ${({ isOpened }: TransferProps) => (isOpened ? "scaleY(1)" : "scaleY(0)")};
  background-color: ${COLOR.SUPER_LIGHT_GREY};
  padding: 20px 30px 30px 30px;
  border-radius: 0 0 20px 20px;
  transition: all 0.2s ease;
  transform-origin: center top;
  z-index: 51;
  box-shadow:
    0 10px 15px -3px ${convertHexToRGBA(COLOR.BLACK, 10)},
    0 4px 6px -4px ${convertHexToRGBA(COLOR.BLACK, 10)};
`

export const SemiBoldText = styled.span`
  font-weight: 600;
`

export const Section = styled.div`
  margin-top: 109px;
`

export const HeadSection = styled.p`
  font-size: 20px;
  margin-bottom: 30px;
`

export const Title = styled.p`
  font-size: 20px;
`

export const InProgressText = styled.p`
  font-size: 12px;
  font-style: italic;
`

export const Wrapper = styled.div`
  position: relative;
`
