import React, { useState } from "react"
import { Button, Col, Row } from "reactstrap"
import Select from "../Form/Select"
import { KeeperStatus } from "types/keeper.types"
import { LabelInput, StatsOutput } from "./PushNotifications.styled"
import FilterBarZipcodes from "../FilterBarZipcodes/FilterBarZipcodes"
import { ZipcodesData } from "types/filter-bar-zipcodes.types"
import { Loader } from "semantic-ui-react"
import { apiGetNbKeepersForPushNotifications } from "services/push-notifications"
import { useDispatch } from "react-redux"
import { KeepersAvailable, SelectData } from "./push-notifications.types"

interface SearchKeepersByZoneProps {
  statuses?: SelectData[]
  zipcodes?: SelectData[]
  keepersAvailable: KeepersAvailable
  handleChangeAvailableKeepers: (keepersAvailable: KeepersAvailable) => void
  handleChangeZipcodes: (statuses: SelectData[]) => void
  handleChangeStatuses: (zipcodes: SelectData[]) => void
}

export const SearchKeepersByZone = ({
  handleChangeAvailableKeepers,
  keepersAvailable,
  handleChangeStatuses,
  handleChangeZipcodes,
  statuses = [],
  zipcodes = [],
}: SearchKeepersByZoneProps) => {
  const dispatch = useDispatch()

  const [loading] = useState(false)

  /**
   * Get how many Keepers have push notifications activated with these filters
   */
  const getNbKeepersForPushNotifications = async () => {
    const r = await dispatch(
      apiGetNbKeepersForPushNotifications({
        statuses: statuses.map(s => s.value).join(","),
        zipcodes: zipcodes.map(z => z.value).join(","),
      }),
    )

    const data: { nbKeepers: number; nbKeepersActive: number } = r?.payload.data
    handleChangeAvailableKeepers({ loaded: true, ...data })
  }

  return (
    <>
      <Row style={{ marginTop: "1em" }}>
        <Col xs={6} md={6}>
          <Select
            menuPosition="fixed"
            defaultValue={[]}
            isMulti
            name="keepers_statuses"
            options={Object.keys(KeeperStatus).map(i => ({ label: i, value: i }))}
            placeholder="Filtrer par statut..."
            onChange={e => handleChangeStatuses(e)}
            label={"Statut des Keepers"}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "1em" }}>
        <Col xs={12} md={12}>
          <LabelInput>Zone des Keepers</LabelInput>
          <FilterBarZipcodes onChangeZipcodes={(zipcodes: ZipcodesData[]) => handleChangeZipcodes(zipcodes)} />
        </Col>
      </Row>
      <Row>
        {!keepersAvailable.loaded && (
          <Col xs={12} md={12}>
            {loading ? (
              <Loader active inline />
            ) : (
              <Button
                disabled={!statuses || !zipcodes || statuses.length === 0 || zipcodes.length === 0}
                onClick={() => getNbKeepersForPushNotifications()}
              >
                Chercher
              </Button>
            )}
          </Col>
        )}
        {keepersAvailable.loaded && (
          <Col xs={6}>
            {keepersAvailable.nbKeepersActive > 0 ? (
              <StatsOutput>
                ✅ <b>{keepersAvailable.nbKeepersActive} Keepers ont activé les notifications push</b> sur un total de{" "}
                {keepersAvailable.nbKeepers} Keepers
              </StatsOutput>
            ) : (
              <>
                <StatsOutput>❌ Aucun Keeper n&apos;a activé la fonctionnalité push sur cette zone</StatsOutput>
                <br />
                <Button
                  disabled={!statuses || !zipcodes || statuses.length === 0 || zipcodes.length === 0}
                  onClick={() => getNbKeepersForPushNotifications()}
                >
                  Chercher
                </Button>
              </>
            )}
          </Col>
        )}
      </Row>
    </>
  )
}
