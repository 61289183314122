import React from "react"
import { Col, Row } from "reactstrap"
import { TabButton, TabButtonsContainer } from "./PushNotifications.styled"
import { SearchKeepersTab } from "./push-notifications.types"

interface SearchKeeperTabsProps {
  selectedSearchKeepersTab: SearchKeepersTab
  handleSearchKeepersTab: (searchKeepersTab: SearchKeepersTab) => void
}
export const SearchKeeperTabs = ({ selectedSearchKeepersTab, handleSearchKeepersTab }: SearchKeeperTabsProps) => {
  return (
    <Row>
      <Col xs={12}>
        <TabButtonsContainer>
          <TabButton
            isSelected={selectedSearchKeepersTab === SearchKeepersTab.SEARCH_BY_ZONE}
            onClick={() => handleSearchKeepersTab(SearchKeepersTab.SEARCH_BY_ZONE)}
          >
            Par statut et zone
          </TabButton>
          <TabButton
            isSelected={selectedSearchKeepersTab === SearchKeepersTab.SEARCH_BY_KEEPER_IDS}
            onClick={() => handleSearchKeepersTab(SearchKeepersTab.SEARCH_BY_KEEPER_IDS)}
          >
            Par IDs
          </TabButton>
        </TabButtonsContainer>
      </Col>
    </Row>
  )
}
